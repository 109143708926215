// src/hooks/useSyncWithQueryParams.js
import { useEffect, useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import debounce from 'lodash.debounce';

/**
 * Custom hook to synchronize state variables with URL query parameters.
 * @param {Array} stateVars - Array of state variable values to sync.
 * @param {Array} setters - Array of setter functions corresponding to stateVars.
 * @param {Array} keys - Array of query parameter keys corresponding to stateVars.
 * @param {number} debounceTime - Debounce time in milliseconds for updating URL.
 */
const useSyncWithQueryParams = (stateVars, setters, keys, debounceTime = 300) => {
  const [searchParams, setSearchParams] = useSearchParams();

  // Function to parse boolean values from strings
  const parseBool = (value) => {
    return value === 'true';
  };

  // List of keys that should be parsed as numbers
  const numericKeys = ['unixTimestamp1', 'unixTimestamp2', 'relocationLat', 'relocationLng', 'c1lat', 'c1lng', 'c2lat', 'c2lng'];

  // Initialize state from URL on mount
  useEffect(() => {
    keys.forEach((key, index) => {
      const value = searchParams.get(key);
      if (value !== null && setters[index]) {
        if (['trines', 'sextiles', 'squares', 'parans', 'second', 'relocate', 'syn', 'mch', 'ma'].includes(key)) {
          // Boolean values
          setters[index](parseBool(value));
        } else if (numericKeys.includes(key)) {
          // Numeric values
          const parsedValue = Number(value);
          setters[index](isNaN(parsedValue) ? setters[index].__DEFAULT__ : parsedValue);
        } else {
          // String values
          setters[index](value);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Function to update search params based on state variables
  const updateParams = useCallback(() => {
    const params = {};

    keys.forEach((key, index) => {
      const value = stateVars[index];
      if (value !== undefined && value !== null && value !== '') {
        if (typeof value === 'boolean') {
          params[key] = value;
        } else if (typeof value === 'number') {
          params[key] = value;
        } else {
          params[key] = value;
        }
      }
    });

    setSearchParams(params, { replace: true }); // Replace to avoid adding history entries
  }, [keys, setSearchParams, stateVars]);

  // Debounce the updateParams function to prevent excessive URL updates
  const debouncedUpdateParams = useMemo(() => debounce(updateParams, debounceTime), [updateParams, debounceTime]);

  // Update URL when relevant state variables change
  useEffect(() => {
    debouncedUpdateParams();
    return () => {
      debouncedUpdateParams.cancel();
    };
  }, [debouncedUpdateParams, stateVars]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      debouncedUpdateParams.cancel();
    };
  }, [debouncedUpdateParams]);
};

export default useSyncWithQueryParams;
