// src/api/cache.js

class LinkedListNode {
    /**
     * Represents a node in a doubly linked list.
     * @param {string} key - The unique key associated with the node.
     * @param {*} value - The value/data stored in the node.
     */
    constructor(key, value) {
      this.key = key;
      this.value = value;
      this.next = null;
      this.prev = null;
    }
  }
  
  class LinkedList {
    /**
     * Represents a doubly linked list to maintain LRU order.
     */
    constructor() {
      this.head = null;
      this.tail = null;
      this.nodes = new Map(); // Maps keys to their corresponding nodes
    }
  
    /**
     * Gets the number of nodes in the linked list.
     * @returns {number} The size of the linked list.
     */
    get size() {
      return this.nodes.size;
    }
  
    /**
     * Moves the node with the specified key to the front (head) of the list.
     * @param {string} key - The key of the node to move.
     */
    moveToFront(key) {
      const node = this.nodes.get(key);
      if (!node) return;
  
      if (node === this.head) return;
  
      // Detach the node from its current position
      if (node.prev) node.prev.next = node.next;
      if (node.next) node.next.prev = node.prev;
      if (node === this.tail) this.tail = node.prev;
  
      // Move node to the front
      node.prev = null;
      node.next = this.head;
      if (this.head) this.head.prev = node;
      this.head = node;
      if (!this.tail) this.tail = node;
    }
  
    /**
     * Adds a new node with the specified key and value to the front of the list.
     * @param {string} key - The key of the node to add.
     * @param {*} value - The value/data of the node to add.
     */
    add(key, value) {
      const node = new LinkedListNode(key, value);
      this.nodes.set(key, node);
  
      if (!this.head) {
        this.head = node;
        this.tail = node;
        return;
      }
  
      node.next = this.head;
      this.head.prev = node;
      this.head = node;
    }
  
    /**
     * Removes the node at the tail (end) of the list.
     * @returns {string|null} The key of the removed node, or null if the list is empty.
     */
    removeTail() {
      if (!this.tail) return null;
  
      const key = this.tail.key;
      this.nodes.delete(key);
  
      if (this.head === this.tail) {
        this.head = null;
        this.tail = null;
      } else {
        this.tail = this.tail.prev;
        if (this.tail) this.tail.next = null;
      }
  
      return key;
    }
  
    /**
     * Removes the node with the specified key from the list.
     * @param {string} key - The key of the node to remove.
     */
    remove(key) {
      const node = this.nodes.get(key);
      if (!node) return;
  
      this.nodes.delete(key);
  
      if (node.prev) node.prev.next = node.next;
      if (node.next) node.next.prev = node.prev;
      if (node === this.head) this.head = node.next;
      if (node === this.tail) this.tail = node.prev;
    }
  }
  
  class AsyncLock {
    /**
     * Manages asynchronous operations to ensure mutual exclusion.
     */
    constructor() {
      this.queue = [];
      this.locked = false;
    }
  
    /**
     * Acquires the lock, executes the provided asynchronous function, and releases the lock.
     * @param {Function} fn - An asynchronous function to execute within the lock.
     * @returns {Promise<*>} The result of the asynchronous function.
     */
    async acquire(fn) {
      await new Promise((resolve) => {
        if (!this.locked) {
          this.locked = true;
          resolve();
        } else {
          this.queue.push(resolve);
        }
      });
  
      try {
        return await fn();
      } finally {
        this.release();
      }
    }
  
    /**
     * Releases the lock and processes the next queued function, if any.
     */
    release() {
      const next = this.queue.shift();
      if (next) {
        next();
      } else {
        this.locked = false;
      }
    }
  }
  
  class CacheEntry {
    /**
     * Represents a cache entry with data, timestamp, and size.
     * @param {*} data - The data to cache.
     * @param {number} timestamp - The time when the entry was cached.
     */
    constructor(data, timestamp) {
      this.data = data;
      this.timestamp = timestamp;
      this.size = new Blob([JSON.stringify(data)]).size;
    }
  
    /**
     * Checks if the cache entry has expired (24 hours).
     * @returns {boolean} True if expired, else false.
     */
    isExpired() {
      return Date.now() - this.timestamp > 24 * 60 * 60 * 1000;
    }
  }
  
  export class CacheManager {
    /**
     * Manages caching of data with LRU eviction and size constraints.
     * @param {number} [maxSizeInMB=300] - The maximum cache size in megabytes.
     */
    constructor(maxSizeInMB = 300) {
      this.cache = new Map(); // Map<string, WeakRef<CacheEntry>>
      this.lruList = new LinkedList(); // Maintains LRU order
      this.mutex = new AsyncLock(); // Ensures mutual exclusion
      this.registry = new FinalizationRegistry((key) => this.cleanup(key)); // Cleans up entries when garbage collected
      this.currentSize = 0;
      this.maxSizeInMB = maxSizeInMB * 1024 * 1024; // Convert to bytes
    }
  
    /**
     * Retrieves the cached data for the specified key.
     * @param {string} key - The key of the cached data.
     * @returns {Promise<*>} The cached data, or null if not found or expired.
     */
    async get(key) {
      return this.mutex.acquire(async () => {
        const ref = this.cache.get(key);
        if (!ref) return null;
  
        const entry = ref.deref();
        if (!entry) {
          this.cleanup(key);
          return null;
        }
  
        if (entry.isExpired()) {
          this.cleanup(key);
          return null;
        }
  
        this.lruList.moveToFront(key);
        return entry.data;
      });
    }
  
    /**
     * Sets the cached data for the specified key.
     * @param {string} key - The key to associate with the cached data.
     * @param {*} value - The data to cache.
     * @returns {Promise<void>}
     */
    async set(key, value) {
      return this.mutex.acquire(async () => {
        const entry = new CacheEntry(value, Date.now());
        const ref = new WeakRef(entry);
  
        this.cache.set(key, ref);
        this.lruList.add(key, entry);
        this.currentSize += entry.size;
        this.registry.register(entry, key);
  
        await this.maintainSize();
      });
    }
  
    /**
     * Ensures that the cache size does not exceed the maximum limit by evicting least recently used entries.
     * @returns {Promise<void>}
     */
    async maintainSize() {
      const batchSize = 10;
      while (this.currentSize > this.maxSizeInMB && this.lruList.size > 0) {
        const keys = [];
        for (let i = 0; i < batchSize && this.lruList.size > 0; i++) {
          const key = this.lruList.removeTail();
          if (key) keys.push(key);
        }
  
        for (const key of keys) {
          const ref = this.cache.get(key);
          const entry = ref?.deref();
          if (entry) {
            this.currentSize -= entry.size;
          }
          this.cleanup(key);
        }
      }
    }
  
    /**
     * Cleans up the cache and LRU list for the specified key.
     * @param {string} key - The key to clean up.
     */
    cleanup(key) {
      this.cache.delete(key);
      this.lruList.remove(key);
    }
  }
  