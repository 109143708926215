import { elementColors, signs } from "../../data/zodiacConstants";
import { bodyReference } from "../../scripts/chart";
import { useBodyViewSettings } from "../../contexts/bodyViewContext";

const getHouseForDegree = (degree, cusps) => {
    for (let i = 0; i < cusps.length; i++) {
        const nextIndex = (i + 1) % cusps.length;
        const start = cusps[i];
        const end = cusps[nextIndex];
        
        if (end > start) {
            if (degree >= start && degree < end) {
                return i + 1;
            }
        } else {
            // Handle case where house spans 0°
            if (degree >= start || degree < end) {
                return i + 1;
            }
        }
    }
    return 1; // Default to first house if not found
};

const BodyPositionCard = (props) => {
    const {positionData, cusps, title} = props;
    const { isBodyVisible } = useBodyViewSettings();

    const zodiacSigns = [
        "Aries", "Taurus", "Gemini", "Cancer",
        "Leo", "Virgo", "Libra", "Scorpio",
        "Sagittarius", "Capricorn", "Aquarius", "Pisces"
      ];

      

    // Filter positions based on body visibility
    const visiblePositions = positionData.positions.filter(pos => isBodyVisible(pos.body));

    return <div 
    key={(positionData && positionData.date) ? positionData.date.getTime() : 'bpc'}
    style={{
      marginBottom: 16,
      padding: 12,
      borderRadius: 4,
      backgroundColor: 'rgba(255,255,255,0)',
      borderLeft: (positionData && positionData.date) ? new Date().toDateString() === positionData.date.toDateString() 
        ? '3px solid white' 
        : '3px solid transparent' : '3px solid transparent'
    }}
  >
    <div style={{ 
      color: 'rgba(255,255,255,.5)',
      textAlign: 'center', width: '100%',
    }}>{title}</div>
    <div style={{ 
      // fontWeight: 'bold',
      marginBottom: 8,
      borderBottom: '1px solid rgba(255,255,255,0.2)',
      paddingBottom: 4,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',color: 'rgba(255,255,255,.5)',
      textAlign: 'center', width: '100%',
    }}>
      
      {positionData.date && <span>{positionData.date.toLocaleDateString('en-US', { 
        weekday: 'short',
        day: 'numeric'
      })}</span>}
    </div>
    <div style={{ fontSize: 14 }}>
      {visiblePositions.map((pos, i) => {
        const bodyInfo = bodyReference[pos.body];
        if (!bodyInfo) {
          return null;
        }
        const signData = signs.filter(sign => sign.name === zodiacSigns[pos.sign])[0];
        const houseNumber = cusps ? getHouseForDegree(pos.degree, cusps) : null;

        return (
          <div key={i} style={{ 
            marginBottom: 4,
            display: 'flex',
            alignItems: 'center',
            gap: 8
          }}>
            <span style={{ minWidth: 64, color: bodyInfo.color, textAlign: 'center', lineHeight: '12px' }} className='symbolText'>
              {bodyInfo.symbol}
            </span>
            <span style={{ 
              flex: 1,
              fontSize: 10,
              display: 'flex',
              alignItems: 'center',
              gap: 8
            }}>
              <span style={{width: 96,}}>
                <span className="symbolText" style={{ lineHeight: '10px', color: elementColors[signData.element]}}>
                  {signData.symbol}
                </span> {zodiacSigns[pos.sign]} {pos.signDegree && pos.signDegree.toFixed(1)}°
                {pos.longitudeSpeed < 0 && ' ℞'} {/* Add retrograde symbol if moving backwards */}
              </span>
              {houseNumber && <span style={{
                color:  bodyInfo.color,//'rgba(255,255,255,0.5)',
                fontSize: 10,
                marginLeft: 4
              }}>
                House {houseNumber}
              </span>}
            </span>
          </div>
        );
      })}
    </div>
  </div>
}

export default BodyPositionCard;
