export const getUniqueRelocSynastryAspects = (relocSynastryAspects, synastryAspects) => {
    // Helper function to compare two aspects deeply
    const areAspectsEqual = (aspect1, aspect2) => {
        return (
            aspect1.point1Label === aspect2.point1Label &&
            aspect1.point2Label === aspect2.point2Label &&
            (aspect1.point1Source === aspect2.point1Source || aspect1.point1Source === aspect2.point1Source+2 || aspect1.point1Source === aspect2.point1Source-2) &&
            (aspect1.point2Source === aspect2.point2Source || aspect1.point2Source === aspect2.point2Source+2 || aspect1.point2Source === aspect2.point2Source-2)
        ) || (
            // Allow for reversed point labels and sources
            aspect1.point1Label === aspect2.point2Label &&
            aspect1.point2Label === aspect2.point1Label &&
            (aspect1.point1Source === aspect2.point2Source || aspect1.point1Source === aspect2.point2Source+2 || aspect1.point1Source === aspect2.point2Source-2) &&
            (aspect1.point2Source === aspect2.point1Source || aspect1.point2Source === aspect2.point1Source+2 || aspect1.point2Source === aspect2.point1Source-2)
        );
    };

    // Filter relocSynastryAspects to include only those not in synastryAspects
    return relocSynastryAspects.filter(relocAspect =>
        !synastryAspects.some(synastryAspect => areAspectsEqual(relocAspect, synastryAspect))
    );
};

export const isAspectInSearch = (aspect, aspectSearch) => {
    if (!aspectSearch || !aspectSearch.length) return false;
    return aspectSearch.some(searchArray => 
        searchArray.some(searchAspect => 
            ((searchAspect.point1Label === aspect.point1Label && 
              searchAspect.point2Label === aspect.point2Label) ||
             (searchAspect.point1Label === aspect.point2Label && 
              searchAspect.point2Label === aspect.point1Label)) &&
           ( ((searchAspect.point1Source === aspect.point1Source && searchAspect.point2Source === aspect.point2Source) || 
             (searchAspect.point1Source === aspect.point1Source && searchAspect.point2Source === aspect.point1Source) || 
             (searchAspect.point2Source === aspect.point2Source && searchAspect.point1Source === aspect.point2Source)) || 

            ((searchAspect.point1Source === aspect.point1Source-2 && searchAspect.point2Source === aspect.point2Source-2) || 
            (searchAspect.point1Source === aspect.point1Source-2 && searchAspect.point2Source === aspect.point1Source-2) || 
            (searchAspect.point2Source === aspect.point2Source-2 && searchAspect.point1Source === aspect.point2Source-2)) )
        )
    );
};