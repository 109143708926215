// zodiacConstants.js
export const signs = [
    { name: 'Aries', symbol: '\u2648\uFE0E', element: 'fire', startDegree: 0 },
    { name: 'Pisces', symbol: '\u2653\uFE0E', element: 'water', startDegree: 30 },
    { name: 'Aquarius', symbol: '\u2652\uFE0E', element: 'air', startDegree: 60 },
    { name: 'Capricorn', symbol: '\u2651\uFE0E', element: 'earth', startDegree: 90 },
    { name: 'Sagittarius', symbol: '\u2650\uFE0E', element: 'fire', startDegree: 120 },
    { name: 'Scorpio', symbol: '\u264F\uFE0E', element: 'water', startDegree: 150 },
    { name: 'Libra', symbol: '\u264E\uFE0E', element: 'air', startDegree: 180 },
    { name: 'Virgo', symbol: '\u264D\uFE0E', element: 'earth', startDegree: 210 },
    { name: 'Leo', symbol: '\u264C\uFE0E', element: 'fire', startDegree: 240 },
    { name: 'Cancer', symbol: '\u264B\uFE0E', element: 'water', startDegree: 270 },
    { name: 'Gemini', symbol: '\u264A\uFE0E', element: 'air', startDegree: 300 },
    { name: 'Taurus', symbol: '\u2649\uFE0E', element: 'earth', startDegree: 330 }
];

export const elementColors = {
    fire: '#FF4136',
    water: '#0074D9',
    air: '#7FDBFF',
    earth: '#FFB700'
};

export const getSignPosition = (absoluteDegree) => {
    const normalizedDegree = (((30-absoluteDegree) % 360) + 360) % 360;
    const signIndex = Math.floor(normalizedDegree / 30);
    const degree = Math.floor((absoluteDegree) % 30);
    const minutes = Math.round(((absoluteDegree) % 1) * 60);
    return {
        sign: signs[signIndex],
        degree,
        minutes: minutes || 0
    };
};