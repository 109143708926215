import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BirthProvider } from './contexts/birthContext';
import ViewProvider from './contexts/viewContext';
import BodiesProvider from './contexts/bodiesContext';
import BodyViewProvider from './contexts/bodyViewContext';
import { UserProvider } from './contexts/userContext';
import { RelocationPathsProvider } from './contexts/relocationPathsContext';
import { PathsProvider } from './contexts/pathsContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <UserProvider>
        <BirthProvider>
          <ViewProvider>
            <BodiesProvider>
              <BodyViewProvider>
                <RelocationPathsProvider>
                  <PathsProvider>
                    <App />
                  </PathsProvider>
                </RelocationPathsProvider>
              </BodyViewProvider>
            </BodiesProvider>
          </ViewProvider>
        </BirthProvider>
      </UserProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
