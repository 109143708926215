// src/hooks/useIsIphone.js

import { useState, useEffect } from 'react';

/**
 * Custom hook to determine if the current device is an iPhone.
 *
 * @returns {boolean} True if the device is an iPhone, else false.
 */
const useIsIphone = () => {
  const [isIphone, setIsIphone] = useState(false);
  const [useragent, setuseragent] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    setuseragent(userAgent)
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPhone/i.test(userAgent)) {
      setIsIphone(true);
    } else {
      setIsIphone(false);
    }
  }, []);

  return isIphone;
};

export default useIsIphone;
