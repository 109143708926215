import Globe from 'react-globe.gl';
import { renderToStaticMarkup } from "react-dom/server"
import { useState, useEffect, memo, useMemo, useRef } from 'react';
import populated_places from '../data/populated_places.json'
import { filterPaths } from '../scripts/chart';
import { chunkArray, divGenerator, processMarkers, targetGenerator } from './globeComponents';
import { workerContent as pathsWorkerContent } from '../workers/pathsWorker'
import { workerContent as synParansWorkerContent } from '../workers/synastryParansWorker'
import { useMemoWithDeepEqual } from '../scripts/helpers';
import { useRelocation } from '../contexts/relocationPathsContext';
import { useBodyViewSettings } from '../contexts/bodyViewContext';
import { usePaths } from '../contexts/pathsContext';
import { useViewData } from '../contexts/viewContext';
import { doCalculateSynastryParans } from '../api/trine-backend.api';

const CHUNK_SIZE = 9; // Number of bodies to process at once

const GlobeMap = memo(({unixTimestamps, bodiesSelection, onGlobeClick, relocation, setRelocation,}) => {
    const globeEl = useRef();
    const [pathsReady, setPathsReady] = useState(false);
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const workerRef = useRef(null);
    const workerSynastryParansRef = useRef(null);
    const requestInProgressRef = useRef(false);
    const pathsRef = useRef([]);
    const expectedChunksRef = useRef(0);
    const receivedChunksRef = useRef(0);

    const { isBodyVisible } = useBodyViewSettings();
    const { getOptions } = useViewData();
    const options = getOptions();
    const { allPaths, addPaths, clearPaths, synastryParans, setSynastryParans, getPathsForChart, pathMarkers } = usePaths();
    const { relocPaths, updateRelocPaths, clearRelocPaths } = useRelocation();

    const initWorker = (content) => {
        try {
          const blob = new Blob([content], { type: 'text/javascript' });
          const worker = new Worker(URL.createObjectURL(blob));
          return worker;
        } catch (error) {
          console.error('Error creating worker:', error); //   throw error;
        }
    }

    const updatePaths = () => {
        if (receivedChunksRef.current === expectedChunksRef.current) {
            addPaths(pathsRef.current);
            setPathsReady(true);
            setLoading(false);
            setProgress(0);
            if (relocation && !isNaN(relocation.lat)) {
                checkRelocatePaths(relocation);
            }
        }
    };
    // const updateSynParans = () => {
    //     if (receivedChunksRef.current === expectedChunksRef.current) {
    //         addPaths(pathsRef.current);
    //         setPathsReady(true);
    //         setLoading(false);
    //         setProgress(0);
    //         if (relocation && !isNaN(relocation.lat)) {
    //             checkRelocatePaths(relocation);
    //         }
    //     }
    // };
      
    useEffect(() => {
        try {
            const worker = initWorker(pathsWorkerContent);
            const synParansWorker = initWorker(synParansWorkerContent);
            worker.onmessage = (e) => {
                const { type, data, chartIndex, chunkInfo } = e.data;
                if (type === 'error') {
                    console.error(`Error in worker for chart ${chartIndex}:`, e.data.error);
                    setLoading(false);
                    return;
                }

                if (type === 'regular') {
                    pathsRef.current = [...pathsRef.current, ...data.paths, ...data.parans];
                    receivedChunksRef.current++;
                    if (chunkInfo) {
                        const chunkProgress = (chunkInfo.current / chunkInfo.total) * (100 / unixTimestamps.length);
                        setProgress(prev => Math.min(prev + chunkProgress, 100));
                    }
                    updatePaths();
                } else if (type === 'synastry') {
                    const processedSynPaths = data.paths.map(path => ({
                        ...path,
                        type: `${path.type}`,
                        synastryPath: true,
                        marker: {...path.marker, synastryPath: true}
                    }));
                    pathsRef.current = [...pathsRef.current, ...processedSynPaths];
                    receivedChunksRef.current++;
                    updatePaths();
                }
            };
            synParansWorker.onmessage = (e) => {
                try {
                    if (e.data && e.data.data) 
                        setSynastryParans(e.data.data.parans);
                } catch(e) {
                    console.log("webworker error", e);
                }
            };
            worker.onerror = (error) => {
                console.error('Worker error:', error);
            };
            workerRef.current = worker;

            synParansWorker.onerror = (error) => {
                console.error('Worker error:', error);
            };
            workerSynastryParansRef.current = synParansWorker;

            worker.postMessage({ type: 'test' });
            synParansWorker.postMessage({ type: 'test' });
        } catch (error) {
            console.error('Error initializing worker:', error);
        }
        return () => {
            workerRef.current?.terminate();
            workerSynastryParansRef.current?.terminate();
            requestInProgressRef.current = false;
        };
    }, []);

    const requestSynastryParans = async (bodiesCombined, timestamps) => {
        const filterOut = ['PartOf', 'Ascendant', 'Descendant', 'Midheaven', 'Imum Coeli', 'Vertex', 'Antivertex'];
        const filterBodies = (bodies) => {
            return bodies.filter(body => filterOut.map(n => !body.name.includes(n)).every(Boolean));
        };
        workerSynastryParansRef.current.postMessage({ 
            bodies1: filterBodies(bodiesCombined[0]), bodies2: filterBodies(bodiesCombined[1]), 
            timestamp1: timestamps[0], timestamp2: timestamps[1], 
            chartIndex1: 1, chartIndex2: 2, type: 'regular', chunkInfo: {current: 1, total: 1}
        })
    }

    const requestPaths = async (bodiesCombined, timestamps) => {
        setLoading(true);
        pathsRef.current = [];
        receivedChunksRef.current = 0;
        expectedChunksRef.current = 0;
        clearPaths();
        setProgress(0);
        setPathsReady(false);
        clearRelocPaths();
        
        // Calculate total expected chunks
        let totalChunks = 0;
        for (let i = 0; i < timestamps.length; i++) {
            const bodies = bodiesCombined[i].map(body => ({
                ...body,
                synastry: i + 1
            }));
            if (bodies.length > 0) {
                const chunks = Math.ceil(bodies.length / CHUNK_SIZE);
                totalChunks += chunks;
            }
        }
        expectedChunksRef.current = totalChunks;

        for (let i = 0; i < timestamps.length; i++) {
          const bodies = bodiesCombined[i].map(body => ({
            ...body,
            synastry: i + 1
          }));
          if (bodies.length > 0) {
            const bodyChunks = chunkArray(bodies, CHUNK_SIZE);
            const totalChunks = bodyChunks.length;
      
            for (let j = 0; j < bodyChunks.length; j++) {
              workerRef.current.postMessage({
                bodies: bodyChunks[j],
                timestamp: timestamps[i],
                chartIndex: i + 1,
                type: 'regular',
                chunkInfo: {
                  current: j + 1,
                  total: totalChunks*2
                }
              })
              await new Promise(resolve => setTimeout(resolve, 100));
            }
          }
        }
        
        if (bodiesCombined.length === 2) {
            
            const reversedStamps = [...timestamps].reverse();
            for (let i = 0; i < reversedStamps.length; i++) {
                const bodies = bodiesCombined[i]
                    .map(body => ({
                    ...body,
                    color: body.color.replace("1)", '.5)'),
                    name: `${body.name}-Syn`,
                    ///bodyName: `${body.name}-Syn`,
                    synastry: i + 1
                    }))
                    .filter(body => !body.name.toLowerCase().includes('part'));
                
                if (bodies.length > 0) {
                    const bodyChunks = chunkArray(bodies, CHUNK_SIZE);
                    const totalChunks = bodyChunks.length;
                    expectedChunksRef.current += totalChunks;
            
                    for (let j = 0; j < bodyChunks.length; j++) {
                    workerRef.current.postMessage({
                        bodies: bodyChunks[j],
                        timestamp: reversedStamps[i],
                        bodyTimestamp: reversedStamps[1-i],
                        chartIndex: i + 1,
                        type: 'synastry',
                        chunkInfo: {
                        current: totalChunks+j + 1,
                        total: totalChunks*2
                        }
                    });
                    await new Promise(resolve => setTimeout(resolve, 100));
                    }
                }
            }
        }

        requestInProgressRef.current = false;
    };

    const stableBodies = useMemoWithDeepEqual(bodiesSelection);

    useEffect(() => { // Consolidated useEffect for path requests
        if (unixTimestamps && stableBodies && !requestInProgressRef.current) {
            requestInProgressRef.current = true;
            requestPaths(stableBodies, unixTimestamps);
        }
        if (unixTimestamps.length === 2 && stableBodies.length === 2) { //synastry parans
            requestSynastryParans(stableBodies, unixTimestamps);
        }
        
        return () => {
            pathsRef.current = [];
            receivedChunksRef.current = 0;
            expectedChunksRef.current = 0;
            clearPaths();
            setProgress(0);
            setLoading(false);
            setPathsReady(false);
            requestInProgressRef.current = false;
        };
    }, [unixTimestamps, stableBodies]); // Depend on both timestamps and bodies

    const globeClick = (e) => {
        if (!allPaths || !allPaths.length || !pathsReady) return;

        const {lat, lng} = e;
        setRelocation(e)
        
        const calculateDistance = (lat1, lon1, lat2, lon2) => {
            const R = 3959;
            const dLat = (lat2 - lat1) * Math.PI / 180;
            const dLon = (lon2 - lon1) * Math.PI / 180;
            const a = 
                Math.sin(dLat/2) * Math.sin(dLat/2) +
                Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * 
                Math.sin(dLon/2) * Math.sin(dLon/2);
            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
            return R * c;
        };

        const findClosestPointOnPath = (lat, lng, pathPoints) => {
            let minDist = Infinity;
            let closestPoint = null;
            
            for (let i = 0; i < pathPoints.length; i++) {
                const point = pathPoints[i];
                const dist = calculateDistance(lat, lng, point[0], point[1]);
                
                if (dist < minDist) {
                    minDist = dist;
                    closestPoint = { latitude: point[0], longitude: point[1] };
                }
            }
            
            return { distance: minDist, point: closestPoint };
        };

        // Create a Set to track unique paths based on name and type
        const uniquePaths = new Map();

        allPaths.forEach(path => {
            // Extract body name from path
            const body = path.marker?.body?.name || path.name.split(' ')[0];
            const trimmedForSynBody = body.split('-Syn')[0];
            const fixBody = trimmedForSynBody === 'North Node' ? "Moon's Nodes" : trimmedForSynBody;

            // Skip if body is not visible
            if (!isBodyVisible(fixBody)) return;

            const { distance, point } = findClosestPointOnPath(lat, lng, path.points);
            const threshold = path.type === 'PARANS' ? 75 : 250;
            
            if (distance <= threshold) {
                const key = `${path.name}-${path.type}`;
                const existingPath = uniquePaths.get(key);
                
                // Only keep the closer path if we have a duplicate
                if (!existingPath || distance < existingPath.distance_mi) {
                    uniquePaths.set(key, {
                        ...path,
                        closestPoint: point,
                        distance_mi: distance,
                        distance_km: distance * 1.60934,
                        relocation: {
                            lat: e.lat,
                            lng: e.lng
                        }
                    });
                }
            }
        });

        if (options.second && synastryParans && synastryParans.length > 0) {
            // synastryParans.forEach(path => {
            //     const body = path.marker?.body?.name || path.name.split(' ')[0];
            //     const trimmedForSynBody = body.split('-Syn')[0];
            //     const fixBody = trimmedForSynBody === 'North Node' ? "Moon's Nodes" : trimmedForSynBody;
            //     if (!isBodyVisible(fixBody)) return;
    
            //     const distance = calculateDistance(lat, lng, path.marker.lat, path.marker.lng);
            //     const point = { latitude: path.marker.lat, longitude: path.marker.lng};

            //     const threshold = 250; //as this is not a paran per se, we'll use the normal threshold
                
            //     if (distance <= threshold) {
            //         const key = `${path.name}-${path.type}`;
            //         const existingPath = uniquePaths.get(key);
                    
            //         // Only keep the closer path if we have a duplicate
            //         // if (!existingPath || distance < existingPath.distance_mi) {
            //             uniquePaths.set(key, {
            //                 ...path,
            //                 name: `${path.name.split(' & ')[0]} ${path.index} & ${path.name.split(' & ')[1]} ${path.index2}`,
            //                 closestPoint: point,
            //                 distance_mi: distance,
            //                 distance_km: distance * 1.60934,
            //                 relocation: {
            //                     lat: e.lat,
            //                     lng: e.lng
            //                 }
            //             });
            //         // }
            //     }
            // });
        }

        // Convert Map values back to array
        const results = Array.from(uniquePaths.values());
        updateRelocPaths(results);
    }

    const checkRelocatePaths = (reloc) => {
        if (!isNaN(reloc.lat) && !isNaN(reloc.lng)) {
            globeEl && globeEl.current && globeEl.current.pointOfView({
                lat: reloc.lat,
                lng: reloc.lng,
                altitude: 1.5
            });
            if (allPaths && allPaths.length > 0 && pathsReady) {
                globeClick(reloc);
            }
        }
    }


    // Effect for handling relocation changes and path updates
    useEffect(() => {
        if (pathsReady && allPaths.length > 0 && relocation && !isNaN(relocation.lat)) {
            checkRelocatePaths(relocation);
        }
    }, [pathsReady, allPaths.length, relocation.lat, relocation.lng]);

    // useEffect(() => {
    //     if (pathsReady && options.second) {
    //         fetchSynastryParans();
    //     }
    // }, [pathsReady, allPaths.length, options.second]);

    // Separate effect for component cleanup
    useEffect(() => {
        return () => {
            clearRelocPaths();
            clearPaths();
            if (globeEl.current) {
                globeEl.current = null;
            }
        };
    }, []);

    const pointsData = useMemo(() => {
        if (allPaths && allPaths.length > 0) {
            
            let filteredPaths;
            if (options.relocate && relocation && !isNaN(relocation.lat)) {
                if (options.second) {
                    
                    if (options.synastry) {
                        filteredPaths = relocPaths;
                    } else {
                        filteredPaths = relocPaths.filter(path => path.marker.synastryPath !== true);
                    }
                } else {
                    
                    filteredPaths = relocPaths.filter(path => {
                        if (path.type === 'PARANS') {
                            return path.marker.index === 1 && path.marker.synastryPath !== true
                        } else {
                            return path.synastry === 1 && path.marker.synastryPath !== true
                        }
                    })
                }
            } else {
                if (options.synastry && options.second && synastryParans && synastryParans.length > 0) {
                    filteredPaths = [...filterPaths(allPaths, options), ...synastryParans];
                    
                } else {
                    filteredPaths = filterPaths(allPaths, options);
                    
                }
            }
            
            // Filter paths based on body visibility
            return filteredPaths.filter(path => {
                const body = path.marker?.body?.name || path.name.split(' ')[0];
                const trimmedForSynBody = body.split('-Syn')[0];
                const fixBody = trimmedForSynBody === 'North Node' ? "Moon's Nodes" : trimmedForSynBody;
                return isBodyVisible(fixBody);
            });
        } else {
            return [];
        }
    }, [allPaths, options, relocation, relocPaths, isBodyVisible, synastryParans]);

    const points = useMemo(() => {
        if (window._globeMarkers) {
            window._globeMarkers.forEach(el => el.remove());
        }
    
        if (!pointsData) {
            window._globeMarkers = new Set();
            return null;
        }
    
        const newElements = new Set();

        const {markers, markerElements} = processMarkers(pointsData);

        let relocMarker = [];
        if (relocation && options.relocate && !isNaN(relocation.lat)) {
            let div = document.createElement('DIV');
            div.innerHTML = renderToStaticMarkup(targetGenerator());
            const element = div.firstChild;
            newElements.add(element);
            relocMarker = [{...relocation, latitude: relocation.lat, longitude: relocation.lng, alt: 0, element }];
        }
    
        let synParanMarker = [];
        if (options.second && synastryParans && synastryParans.length > 0) {
            synastryParans.forEach(paran => {
                let div = document.createElement('DIV');
                div.innerHTML = renderToStaticMarkup(divGenerator(paran));
                const element = div.firstChild;
                newElements.add(element);
                synParanMarker.push({...paran, latitude: paran.lat, longitude: paran.lng, alt: 0, element });
            });
        }
        window._globeMarkers = new Set([...newElements, ...markerElements]);
    
        return [...markers, ...relocMarker, ...synParanMarker];
    }, [pointsData, options.relocate, relocation, options.second]);

    const ringsData = (options.relocate && relocation) ? [{lat: relocation.lat, lng: relocation.lng, radius: 250.0/69.0}] : []
    
    const labelsProps = useMemo(() => ({
        labelsData: populated_places.features,
        labelLat: d => d.properties.latitude,
        labelLng: d => d.properties.longitude,
        labelText: d => d.properties.name,
        labelSize: d => (Math.sqrt(d.properties.pop_max) * 4e-4),
        labelDotRadius: d => 0.3,
        labelColor: () => 'rgba(255, 244, 244, 0.54)',
        labelResolution: 2,
    }), [populated_places])

    const ringsProps = useMemo(() => ({
        ringsData: ringsData,
        ringMaxRadius: (p) => p.radius,
        ringRepeatPeriod: 600,
        ringPropagationSpeed: 1.2,
    }), [ringsData])

    const pathsProps = useMemo(() => ({
        pathsData: pointsData ? pointsData : [],
        pathPoints: points => (points.points),
        pathPointLat: d => d[0],
        pathPointLng: d => d[1],
        pathLabel: (p) => p.name,
        pathColor: (p) => p.color,
        pathDashLength: p => p.length,
        pathDashGap: p => p.gap,
        pathStroke: p => p.strokeWidth,
        pathDashAnimateTime: 120000,
    }), [pointsData]);

    const htmlData =  useMemo(() => ({
        htmlElementsData: points,
        htmlLat: d => d.lat,
        htmlLng: d => d.lng,
        htmlAltitude: d => d.alt,
        htmlElement: d => d.element,
    }), [points])
    
    if (!bodiesSelection) return null;
    return <div style={{width: '100vw', position: 'relative'}}>
        {loading && Math.round(progress) < 100 && Math.round(progress) > 0 &&  (
            <div style={{
                position: 'absolute',
                top: '40vh',
                left: '50%',
                transform: 'translateX(-50%)',
                zIndex: 1000,
                background: 'rgba(0,0,0,0.7)',
                padding: '10px 20px',
                borderRadius: '5px',
                color: 'white'
            }}>
                Calculating paths... {Math.round(progress)}%
            </div>
        )}
        <Globe
            ref={globeEl}
            globeImageUrl="earth-dark.jpg"
            showAtmosphere={true}
            {...labelsProps}
            {...ringsProps}
            {...pathsProps}
            {...htmlData}
            onGlobeClick={globeClick}
        />
    </div>
})

export default GlobeMap
