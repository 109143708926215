import React, { createContext, useContext, useState } from 'react';

const BodiesContext = createContext(null);

export const BodiesProvider = ({ children }) => {
  const [bodies, setBodies] = useState([]);
  const [bodies2, setBodies2] = useState([]);
  const [angleBodies, setAngleBodies] = useState({});
  const [cusps, setCusps] = useState({});
  const [aspects, setAspects] = useState({});
  const [parts, setParts] = useState({});
  const [asteroids, setAsteroids] = useState({});

  const setBodiesNo = (b, n) => {
    if (n === 1) setBodies(b);
    if (n === 2) setBodies2(b);
  };

  const getBodiesNo = (n) => {
    if (n === 1) return bodies;
    if (n === 2) return bodies2;
  };

  const setAngleBodiesNo = (AB, n) => setAngleBodies(prevState => ({...prevState, [n]: AB}));
  const getAngleBodiesNo = n => angleBodies[n] || [];

  const setCuspsNo = (C, n) => setCusps(prevState => ({...prevState, [n]: C}));
  const getCuspsNo = n => cusps[n] || [];

  const setAspectsNo = (C, n) => setAspects(prevState => ({...prevState, [n]: C}));
  const getAspectsNo = n => aspects[n] || [];

  const setPartsNo = (C, n) => setParts(prevState => ({...prevState, [n]: C}));
  const getPartsNo = n => parts[n] || [];
  
  const setAsteroidsNo = (C, n) => setAsteroids(prevState => ({...prevState, [n]: C}));
  const getAsteroidsNo = n => asteroids[n] || []

  const value = {
    bodies,
    bodies2,
    aspects,
    cusps,
    parts,
    angleBodies,
    setBodiesNo,
    getBodiesNo,
    setAngleBodiesNo,
    getAngleBodiesNo,
    setCuspsNo,
    getCuspsNo,
    setAspectsNo,
    getAspectsNo,
    setPartsNo,
    getPartsNo,
    setAsteroidsNo,
    getAsteroidsNo,
  };

  return <BodiesContext.Provider value={value}>{children}</BodiesContext.Provider>;
};

export const useBodiesData = () => {
  const context = useContext(BodiesContext);
  if (!context) throw new Error('useBodiesData must be used within BodiesProvider');
  return context;
};

export default BodiesProvider;