import React, { useState, useEffect, useMemo } from 'react';
import { getPowerlines, getSynastryPowerlines } from '../../api/trine-backend.api';
import { MarkerDivFromLine } from '../globeComponents';
import { useRelocation } from '../../contexts/relocationPathsContext';
import { useBodyViewSettings } from '../../contexts/bodyViewContext';
import { ASPECT_TYPES } from '../../scripts/aspects';

const createPowerlinePatterns = (lines, isBodyVisible) => {
  return lines
    .filter(line => !line.name.includes('-Syn'))
    .map(line => {
      let type = line.type?.toLowerCase()?.replace(/s$/, '') || 'conjunct';
      if (type === 'squares') type = 'square';
      
      const body = line.marker?.body?.name || line.bodyName || line.name.split(' ')[0];
      const angle = (line.marker?.lineLabel?.replace(/[^A-Za-z]/g, '') || line.name.split(' ').pop());

      const fixBody = body === 'North Node' ? "Moon's Nodes" : body;
      const fixType = type === 'main' ? 'conjunct' : type;

      // Skip if body is not visible
      if (!isBodyVisible(fixBody)) return null;

      return {
        type: fixType,
        bodies: body === 'North Node' ? ["Moon's Nodes"] : [body], 
        angle: angle.toUpperCase(),
        displayKey: `${fixBody} ${fixType} ${angle.toUpperCase()}`
      };
    })
    .filter(Boolean); // Remove null entries
};

const createSynPowerlinePatterns = (lines, isBodyVisible) => {
  return lines
    .filter(line => line.name.includes('-Syn'))
    .map(line => {
      let type = line.type?.toLowerCase()?.replace(/s$/, '') || 'conjunct';
      if (type === 'squares') type = 'square';
      
      const body = line.marker?.body?.name || line.bodyName || line.name.split(' ')[0];
      const angle = (line.marker?.lineLabel?.replace(/[^A-Za-z]/g, '') || line.name.split(' ').pop());

      const bodyTrim = body.split('-Syn')[0];
      const fixBody = bodyTrim === 'North Node' ? "Moon's Nodes" : bodyTrim;
      const fixType = type === 'main' ? 'conjunct' : type;

      // Skip if body is not visible
      if (!isBodyVisible(fixBody)) return null;

      return {
        type: `synastry ${fixType}`,
        bodies: bodyTrim === 'North Node' ? ["Moon's Nodes"] : [bodyTrim], 
        angle: angle.toUpperCase(),
        displayKey: `${fixBody} ${fixType} ${angle.toUpperCase()}`
      };
    })
    .filter(Boolean); // Remove null entries
};

const InterpretationHeader = ({ selectedLine, interpretations, setSelectedLine, maxHeight }) => {
  const hasInterpretation = selectedLine?.interpretationKey && interpretations[selectedLine.interpretationKey];
  
  return (
    <div style={{
      position: 'absolute',
      fontSize: 12,
      zIndex: 1,
      color: hasInterpretation ? 'white' : 'rgba(255,255,255,.5)',
      height: hasInterpretation ? 'auto' : maxHeight ? 48 : 64,
      maxHeight: maxHeight ? maxHeight : hasInterpretation ? 360 : 'auto',
      bottom: 0,
      left: 0,
      width: 'calc(100% - 34px)',
      borderTop: '1px solid rgba(255,255,255,.3)',
      padding: 16,
      backgroundColor: 'rgba(0,0,0,.8)',
      backdropFilter: 'blur(2px)',
      transition: 'all 0.5s ease',
    }}>
      
      {selectedLine && <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 8, marginTop: 16}}>
        <MarkerDivFromLine line={selectedLine} globe={false} />
        <div style={{color: selectedLine.color, fontSize: 14, marginLeft: 8 }}>{lineNameWithIcon(selectedLine)}</div>
        <div style={{flex: 1}}></div>
        <button 
              onClick={() => {
                setSelectedLine(null)
              }}
              style={{
                  fontSize: 12,
                  padding: '4px 6px',
                  backgroundColor: 'rgba(255,244,244,0.2)',
                  border: '1px solid #ff4444',
                  borderRadius: 4,
                  color: 'white',
                  cursor: 'pointer'
              }}
          >
              ✕
          </button>
      </div>}
      <div style={{overflowY: 'scroll', maxHeight: 'inherit'}}>
      {hasInterpretation && hasInterpretation.length ? hasInterpretation.map((interpretation, i) => {
        return <div key={`${selectedLine.interpretationKey}-${i}`}>
          <div style={{fontSize: 11, lineHeight: '16px', whiteSpace: interpretation.source === 'trine earth' ? 'pre-wrap' : ''}}>{interpretation.interpretation}</div>
          <div style={{width: '100%', textAlign: 'right', color: 'rgba(255,255,255,.5)', marginTop: 8, fontSize: 9, marginBottom: 8}}>
                excerpt from <span style={{textDecoration: 'underline', cursor: 'pointer', color: 'magenta'}} onClick={() => {
                    window.open(interpretation.sourceUrl, '_blank')
                }}>{interpretation.source}</span> on {new Date(interpretation.createdAt ? interpretation.createdAt._seconds ? interpretation.createdAt._seconds*1000 : interpretation.createdAt : interpretation.createdAt).toDateString()}</div>
        </div>}) : selectedLine ? 'NO INTERPRETATION YET' :
        'SELECT A LINE TO READ THE DESCRIPTION'}
        <div style={{minHeight: (hasInterpretation && hasInterpretation.length>1) ? 64 : 0}} />
        </div>
    </div>
  );
};

const processLineKey = (line) => {
  let type = line.type?.toLowerCase()?.replace(/s$/, '') || 'conjunct';
  if (type === 'squares') type = 'square';
  if (type === 'main') type = 'conjunct';
  
  const body = line.marker?.body?.name || line.bodyName || line.name.split(' ')[0];
  const trimmedForSynBody = body.split('-Syn')[0];
  const angle = line.marker?.lineLabel?.replace(/[^A-Za-z]/g, '') || line.name.split(' ').pop();
  
  return {
    ...line, 
    interpretationKey: `${trimmedForSynBody} ${line.synastryPath ? 'synastry ' : ''}${type} ${angle.toUpperCase()}`,
  }
};

const lineNameWithIcon = line => {
  const lineType = line.type.toLowerCase() === 'main' ? 'conjunction' : line.type.toLowerCase().endsWith('s') ? line.type.toLowerCase().slice(0,-1) : line.type.toLowerCase();
  if (lineType === 'paran') return line.name;
  const nameTokens = line.name.split(' ');
  
  const nameWithAspectIcon = `${nameTokens[0]}${line.name.includes('Node') ? (' ' + nameTokens[1]) : ''} ${ASPECT_TYPES[lineType].symbol} ${line.name.includes('Node') ? nameTokens[3] : nameTokens[2]}`
  return nameWithAspectIcon
}

export const LinesList = ({ maxHeight, emptyMessage = "turn on relocation and click a spot" }) => {
  const { relocPaths } = useRelocation();
  const { isBodyVisible } = useBodyViewSettings();
  const [interpretations, setInterpretations] = useState({});
  const [selectedLine, setSelectedLine] = useState(null);

  // Sort and process lines in a single memoized operation to avoid multiple array copies
  const processedLines = useMemo(() => {
    if (!relocPaths.length) return [];
    return relocPaths
      .slice() // Create a single copy for sorting
      .sort((a, b) => (a.distance_mi < b.distance_mi ? -1 : 1))
      .map(processLineKey)
      .filter(line => {
        const body = line.marker?.body?.name || line.bodyName || line.name.split(' ')[0];
        const trimmedForSynBody = body.split('-Syn')[0];
        const fixBody = trimmedForSynBody === 'North Node' ? "Moon's Nodes" : trimmedForSynBody;
        return isBodyVisible(fixBody);
      });
  }, [relocPaths, isBodyVisible]);

  useEffect(() => {
    const fetchPowerlines = async () => {
      if (!relocPaths.length) return;
      
      try {
        const patterns = createPowerlinePatterns(relocPaths, isBodyVisible);
        const synPatterns = createSynPowerlinePatterns(relocPaths, isBodyVisible);
        
        if (!patterns.length && !synPatterns.length) return;

        const interpretationsMap = {};

        if (patterns.length) {
          const response = await getPowerlines(patterns);
          response.powerlines.forEach(line => {
            const key = `${line.body === "Moon's Nodes" ? "North Node" : line.body} ${line.type} ${line.angle}`;
            if (!interpretationsMap[key]) {
              interpretationsMap[key] = [];
            }
            // Only add if interpretation doesn't already exist
            if (!interpretationsMap[key].some(existing => 
              existing.interpretation === line.interpretation && 
              existing.source === line.source
            )) {
              interpretationsMap[key].push(line);
            }
          });
        }

        if (synPatterns.length) {
          const responseSynastry = await getSynastryPowerlines(synPatterns);
          responseSynastry.powerlines.forEach(line => {
            // const key = `${line.body === "Moon's Nodes" ? "North Node" : line.body} synastry ${line.type} ${line.angle}`;
            const key = `${line.body === "Moon's Nodes" ? "North Node" : line.body} ${line.type} ${line.angle}`;
            if (!interpretationsMap[key]) {
              interpretationsMap[key] = [];
            }
            // Only add if interpretation doesn't already exist
            if (!interpretationsMap[key].some(existing => 
              existing.interpretation === line.interpretation && 
              existing.source === line.source
            )) {
              interpretationsMap[key].push(line);
            }
          });
        }
        setInterpretations(interpretationsMap);
      } catch (error) {
        console.error('Error fetching powerlines:', error);
      }
    };

    fetchPowerlines();
  }, [relocPaths, isBodyVisible]); // Added isBodyVisible dependency

  if (!relocPaths.length) {
    return (
      <div style={{padding: 16, color: 'white', display: 'flex', flexDirection: 'column', overflowY: 'scroll', position: '', maxHeight: 'calc(100% - 40px)', pointerEvents: 'auto'}}>
        {emptyMessage}
      </div>
    );
  }

  return (
    <div style={{padding: 16, display: 'flex', flexDirection: 'column', overflowY: 'scroll', position: '', maxHeight: 'calc(100% - 40px)', pointerEvents: 'auto', width: maxHeight?266 : 'calc(100% - 48px)' }}>
      <InterpretationHeader selectedLine={selectedLine} interpretations={interpretations} setSelectedLine={setSelectedLine} maxHeight={maxHeight} />
      {processedLines.map((line, i) => {
        const hasInterpretation = line?.interpretationKey && interpretations[line.interpretationKey] && interpretations[line.interpretationKey].length;
        return <div 
          key={`relocUIModal-lineline-${line.name}-${i}`}
          style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', color: 'white', marginBottom: 4, cursor: 'pointer'}}
          onClick={() => setSelectedLine(line)}
        >
          <div style={{display: 'flex', width: 48, margin: 4, position: 'relative', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', fontSize: 8}}>
            <MarkerDivFromLine line={line} globe={false} />
          </div>
          <div style={{color: hasInterpretation ? 'white' : 'rgba(255,255,255,.5)', fontSize: 12, fontWeight: 300}} className='symbolText'>{lineNameWithIcon(line)}</div>
          <div style={{flex: 1}} />
          <div style={{fontSize: 10, opacity: .5, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <div>{Math.floor(line.distance_mi * 10) / 10} mi</div>
            <div>{Math.floor(line.distance_km * 10) / 10} km</div>
          </div>
        </div>
      })}
      <div style={{height: 200, minHeight: maxHeight ? 200 : '70px'}} />
    </div>
  );
};
