import { useViewData } from "../../../contexts/viewContext";
import BodyPositionCard from "../../planetarytables/BodyPositionCard";
import HousePositionCard from "../../planetarytables/HousePositionCard";
import { LinesList } from "../../powerlines/LinesList";
import TransitModule from "../../transits/TransitModule";
import Sidebar from "./Sidebar";

const buttonStyle = {
  flex: 1,
  padding: '12px',
  backgroundColor: 'transparent',
  border: 'none',
  color: 'white',
  cursor: 'pointer'
};

const activeButtonStyle = {
  ...buttonStyle,
  backgroundColor: 'rgba(255,255,255,0.1)'
};

const SidebarRelocationTransits = (props) => {

    const isSynastryChart = i => props.wheels[i].props.children && props.wheels[i].props.children.props && props.wheels[i].props.children.props.synastry

    const {
        getOptions,
        getIsMobile,
        getLineSidebarOpen,
        setLineSidebarOpen,
        getRelocTransitTab,
        setRelocTransitTab,
    } = useViewData();

    const options = getOptions();

    return !getIsMobile() && <Sidebar 
        isOpen={getLineSidebarOpen() && options.relocate} 
        setIsOpen={setLineSidebarOpen} 
        buttonProps={{show: options.relocate, icon: '∅', right: 56}} 
        sidebarProps={{background: 'rgba(0,0,0,.5)'}}
    >
        <div style={{color: 'white', width: '100%', padding: 16, textAlign: 'left'}}>&#x25B3;&#xFE0E; trine &#x2727;&#xFE0E; earth &#x2641;&#xFE0E;</div>
        <div style={{
            display: 'flex',
            borderBottom: '1px solid rgba(255,255,255,0.1)',
            backgroundColor: 'rgba(0,0,0,0.8)',
            width: '100%',
            borderRadius: '8px 8px 0 0',
            overflow: 'hidden',
        }}>
            <button
            onClick={() => setRelocTransitTab('charts')}
            style={getRelocTransitTab() === 'charts' ? activeButtonStyle : buttonStyle}
            >
            CHARTS
            </button>
            <button
            onClick={() => setRelocTransitTab('relocation')}
            style={getRelocTransitTab() === 'relocation' ? activeButtonStyle : buttonStyle}
            >
            LINES
            </button>
            <button
            onClick={() => setRelocTransitTab('transits')}
            style={getRelocTransitTab() === 'transits' ? activeButtonStyle : buttonStyle}
            >
            TRANSITS
            </button>

            <button
            onClick={() => {}}
            style={getRelocTransitTab() === 'none' ? activeButtonStyle : buttonStyle}
            >
            </button>
        </div>

        <div style={{ height: 'calc(100% - 48px)', overflowY: 'hidden' }}>
          {getRelocTransitTab() === 'charts' ? (
            <div style={{overflowX: 'hidden',color: 'white', overflowY: 'scroll', height: '100%', display: 'flex', flexDirection: 'column', gap: 16}}>
              <div style={{height: 24, minHeight: 24}}></div>
              {props.wheels && props.wheels.map((wheel, i) => {
                if (isSynastryChart(i)) {
                  return null;
                }
                return <><BodyPositionCard 
                    title={props.wheels[i].props.title}
                    positionData={{
                    positions: props.wheels[i].props.bodies.map(body => ({
                      body: body.name,
                      sign: Math.floor(body.degree / 30),
                      signDegree: body.degree % 30,
                      degree: body.degree,
                      longitudeSpeed: body.longitudeSpeed || 0
                    }))}} 
                    cusps={props.wheels[i].props.cusps}
                />
                    <HousePositionCard cusps={props.wheels[i].props.cusps} bodies={props.wheels[i].props.bodies}  />
                </>
              })}
              <div style={{height: 64, minHeight: 64}}></div>
            </div>
            ) : getRelocTransitTab() === 'relocation' ? (
            <>
              {/* <div style={{color: 'white', width: '100%', padding: 16, textAlign: 'left'}}>
                RELOCATION - Power Lines
              </div> */}
              <LinesList maxHeight={600} />
            </>
          ) : (
            <TransitModule />
          )}
        </div>
    </Sidebar>
}

export default SidebarRelocationTransits;
