// src/hooks/useIsStandalone.js

import { useState, useEffect } from 'react';

/**
 * Custom hook to determine if the app is running in standalone mode.
 *
 * @returns {boolean} True if the app is in standalone mode, else false.
 */
const useIsStandalone = () => {
  const getStandalone = () => {
    // Check for display-mode: standalone
    if (window.matchMedia('(display-mode: standalone)').matches) {
      return true;
    }

    // iOS Safari
    if (window.navigator.standalone) {
      return true;
    }

    // Fallback
    return false;
  };

  const [isStandalone, setIsStandalone] = useState(getStandalone());

  useEffect(() => {
    // Function to update the standalone state
    const handleChange = () => {
      setIsStandalone(getStandalone());
    };

    // Listen for changes in display mode
    const mediaQuery = window.matchMedia('(display-mode: standalone)');
    mediaQuery.addEventListener('change', handleChange);

    // Listen for iOS standalone changes
    window.addEventListener('resize', handleChange); // iOS does not support display-mode media query

    return () => {
      mediaQuery.removeEventListener('change', handleChange);
      window.removeEventListener('resize', handleChange);
    };
  }, []);

  return isStandalone;
};

export default useIsStandalone;
