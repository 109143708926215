import React, { createContext, useContext, useState } from 'react';

const ViewContext = createContext(null);

export const ViewProvider = ({ children }) => {
  const [viewState, setViewState] = useState({
    isMobile: false,
    modalOpen: false,
    activeTab: 0,
    modalOpenAspect: false,
    activeTabAspect: 0,
    activeTabTransit: 0,
    sidebarOpen: false,
    lineSidebarOpen: false,
    chartTab: 0,
    relocTransitTab: 'relocation',
    options: {
      trines: false,
      sextiles: false,
      squares: false,
      parans: false,
      second: false,
      relocate: false,
      synastry: false,  // Added synastry option to initial state
    }
  });

  const getIsMobile = () => viewState.isMobile;
  const getModalOpen = () => viewState.modalOpen;
  const getActiveTab = () => viewState.activeTab;
  const getModalOpenAspect = () => viewState.modalOpenAspect;
  const getActiveTabAspect = () => viewState.activeTabAspect;
  const getActiveTabTransit = () => viewState.activeTabTransit;
  const getSidebarOpen = () => viewState.sidebarOpen;
  const getChartTab = () => viewState.chartTab;
  const getLineSidebarOpen = () => viewState.lineSidebarOpen;
  const getRelocTransitTab = () => viewState.relocTransitTab;
  const getOptions = () => viewState.options;

  const setIsMobile = (value) => setViewState((prev) => ({ ...prev, isMobile: value }));
  const setModalOpen = (value) => setViewState((prev) => ({ ...prev, modalOpen: value }));
  const setActiveTab = (value) => setViewState((prev) => ({ ...prev, activeTab: value }));
  const setModalOpenAspect = (value) => setViewState((prev) => ({ ...prev, modalOpenAspect: value }));
  const setActiveTabAspect = (value) => setViewState((prev) => ({ ...prev, activeTabAspect: value }));
  const setActiveTabTransit = (value) => setViewState((prev) => ({ ...prev, activeTabTransit: value }));
  const setSidebarOpen = (value) => setViewState((prev) => ({ ...prev, sidebarOpen: value }));
  const setChartTab = (value) => setViewState((prev) => ({ ...prev, chartTab: value }));
  const setLineSidebarOpen = (value) => setViewState((prev) => ({ ...prev, lineSidebarOpen: value }));
  const setRelocTransitTab = (value) => setViewState((prev) => ({ ...prev, relocTransitTab: value }));
  const setOptionKey = (key, value) => {
    setViewState((prev) => ({
      ...prev,
      options: {
        ...prev.options,
        [key]: value
      }
    }));
  };
  const setOptions = (value) => {
    if (typeof value === 'function') {
      setViewState((prev) => ({
        ...prev,
        options: value(prev.options)
      }));
    } else {
      setViewState((prev) => ({
        ...prev,
        options: value
      }));
    }
  };

  const value = {
    viewState,
    getIsMobile,
    setIsMobile,
    getModalOpen,
    setModalOpen,
    getActiveTab,
    setActiveTab,
    getModalOpenAspect,
    setModalOpenAspect,
    getActiveTabAspect,
    setActiveTabAspect,
    getActiveTabTransit,
    setActiveTabTransit,
    getSidebarOpen,
    setSidebarOpen,
    getChartTab,
    setChartTab,
    getLineSidebarOpen,
    setLineSidebarOpen,
    getRelocTransitTab,
    setRelocTransitTab,
    getOptions,
    setOptions,
    setOptionKey,
  };

  return <ViewContext.Provider value={value}>{children}</ViewContext.Provider>;
};

export const useViewData = () => {
  const context = useContext(ViewContext);
  if (!context) throw new Error('useViewData must be used within ViewProvider');
  return context;
};

export default ViewProvider;
