export const ASPECT_TYPES = {
    conjunction: {
        angle: 0,
        color: 'rgba(255,255,255,1)',
        orb: 4,
        level: 'major',
        symbol: '☌' // Unicode for conjunction
    },
    opposition: {
        angle: 180,
        color: 'rgba(255,0,0,1)',
        orb: 6,
        level: 'major',
        symbol: '☍' // Unicode for opposition
    },
    trine: {
        angle: 120,
        color: 'rgba(255,0,255,1)',
        orb: 4,
        level: 'major',
        symbol: '△' // Unicode for trine
    },
    square: {
        angle: 90,
        color: 'rgba(0,0,255,1)',
        orb: 5,
        level: 'major',
        symbol: '▢' // Unicode for square
    },
    sextile: {
        angle: 60,
        color: 'rgba(0,255,255,1)',
        orb: 3,
        level: 'major',
        symbol: '✶' // Unicode for sextile
    },
    quincunx: {
        angle: 150,
        color: 'rgba(51,255,0,0.3)',
        orb: 3,
        level: 'major',
        symbol: '⚻' // Unicode for quincunx
    }
};



export const getNormalizedBodyKey = (bodyLabel) => {
    if (!bodyLabel) return null;
    return bodyLabel.toLowerCase().replace(/\s/g,'')
};
export const findRelatedAspects = (selectedAspect, allAspects) => {
    if (!selectedAspect) return [];

    // Function to normalize source value to number
    const normalizeSource = (source) => Number(source);

    const point1Aspects = [...allAspects].filter(aspect => {
        const body1 = getNormalizedBodyKey(selectedAspect.point1Label);
        const source1 = normalizeSource(selectedAspect.point1Source);

        return (
            (getNormalizedBodyKey(aspect.point1Label) === body1 && 
             normalizeSource(aspect.point1Source) === source1) ||
            (getNormalizedBodyKey(aspect.point2Label) === body1 && 
             normalizeSource(aspect.point2Source) === source1)
        );
    });

    const point2Aspects = [...allAspects].filter(aspect => {
        const body2 = getNormalizedBodyKey(selectedAspect.point2Label);
        const source2 = normalizeSource(selectedAspect.point2Source);

        return (
            (getNormalizedBodyKey(aspect.point1Label) === body2 && 
             normalizeSource(aspect.point1Source) === source2) ||
            (getNormalizedBodyKey(aspect.point2Label) === body2 && 
             normalizeSource(aspect.point2Source) === source2)
        );
    });

    const selectedAspectKey = createAspectKey(selectedAspect);
    return [
        point1Aspects.filter(aspect => createAspectKey(aspect) !== selectedAspectKey),
        point2Aspects.filter(aspect => createAspectKey(aspect) !== selectedAspectKey)
    ];
};

const createAspectKey = (aspect) => {
    if (!aspect?.point1Label || !aspect?.point2Label || !aspect?.aspectKey) return null;
    
    const points = [
        `${getNormalizedBodyKey(aspect.point1Label)}-${aspect.point1Source}`,
        `${getNormalizedBodyKey(aspect.point2Label)}-${aspect.point2Source}`
    ].sort();
    
    return `${points[0]}-${points[1]}-${aspect.aspectKey}`;
};

export const addNameKeyToAspects = (aspectList, chartKeys) => {
    return aspectList.map(aspect => {return {...aspect, ...chartKeys}})
}