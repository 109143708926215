// src/components/LineViewSettings.js

import React from 'react';

import './LineViewSettings.css'; // Optional: For custom styling
import { bodyReference } from '../../scripts/chart';
import { useBodyViewSettings } from '../../contexts/bodyViewContext';

/**
 * Component to render a list of celestial bodies with toggle switches to control their visibility.
 *
 * @returns {JSX.Element} The rendered component.
 */
const LineViewSettings = () => {
  const {
    viewState,
    isBodyVisible,
    setBodyVisibility,
    toggleBodyVisibility,
  } = useBodyViewSettings();

  /**
   * Handles the toggle switch change event.
   *
   * @param {string} bodyName - The name of the celestial body.
   */
  const handleToggle = (bodyName) => {
    toggleBodyVisibility(bodyName);
  };

  return (
    <div className="line-view-settings">
      <ul className="body-list">
        {Object.entries(bodyReference).map(([bodyName, bodyData]) => (
          <li key={bodyName} className="body-item">
            <span className="body-symbol symbolText" title={bodyName} style={{color: bodyData.color, fontSize: bodyData.symbol.length>1 ? 12 : 14}}>
              {bodyData.symbol}
            </span>
            <span className="body-name">{bodyName}</span>
            <label className="toggle-switch">
              <input
                type="checkbox"
                checked={isBodyVisible(bodyName)}
                onChange={() => handleToggle(bodyName)}
              />
              <span className="slider"></span>
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LineViewSettings;
