import React, { useState, useEffect, useCallback, memo, useMemo } from 'react';
import { dateToUnix, getTimezoneOffset } from '../../scripts/helpers';

// Optimized debounce hook
const useDebounce = (callback, delay) => {
  const timeoutRef = React.useRef(null);

  return useCallback((...args) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    
    timeoutRef.current = setTimeout(() => {
      callback(...args);
    }, delay);
  }, [callback, delay]);
};

// Memoized timezone grouping
const useGroupedTimezones = () => {
  return useMemo(() => {
    const timezones = Intl.supportedValuesOf('timeZone');
    return timezones.reduce((acc, tz) => {
      const continent = tz.split('/')[0];
      if (!acc[continent]) {
        acc[continent] = [];
      }
      acc[continent].push(tz);
      return acc;
    }, {});
  }, []);
};

const TimezoneSelect = memo(({ timezone, onChange, date, time }) => {
    const groupedTimezones = useGroupedTimezones();
    
    const timezoneOffsets = useMemo(() => {
      if (!date || !time) return {};
      try {
        const dateObj = new Date(`${date}T${time}`);
        if (isNaN(dateObj.getTime())) return {};
        
        const offsets = {};
        Object.values(groupedTimezones).flat().forEach(tz => {
          try {
            offsets[tz] = getTimezoneOffset(tz, dateObj);
          } catch {
            offsets[tz] = '';
          }
        });
        return offsets;
      } catch {
        return {};
      }
    }, [groupedTimezones, date, time]);
  
    return (
      <select
        value={timezone}
        onChange={onChange}
        style={{maxWidth: 200}}
      >
        {Object.entries(groupedTimezones).map(([continent, zones]) => (
          <optgroup key={continent} label={continent.replace('_', ' ')}>
            {zones.map((tz) => (
              <option key={tz} value={tz}>
                {`${tz.replace('_', ' ')} ${timezoneOffsets[tz] ? `(${timezoneOffsets[tz]})` : ''}`}
              </option>
            ))}
          </optgroup>
        ))}
      </select>
    );
  });

// Extracted DateInput component
const DateInput = memo(({ value, onChange }) => (
  <input
    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
    id="date"
    name="date"
    type="date"
    style={{fontSize: 10}}
    value={value}
    onChange={onChange}
  />
));

// Extracted TimeInput component
const TimeInput = memo(({ value, onChange }) => (
  <input
    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
    id="time"
    name="time"
    type="time"
    style={{fontSize: 10}}
    value={value}
    onChange={onChange}
  />
));

const DateSelector = ({ date, time, timezone, setDate, setTime, setTimezone, setUnixTimestamp }) => {
  const [localDate, setLocalDate] = useState(date);
  const [localTime, setLocalTime] = useState(time);
  

//   const debouncedSetDate = useDebounce(setDate, 1200);
//   const debouncedSetTime = useDebounce(setTime, 1200);
//   const debouncedSetTimezone = useDebounce(setTimezone, 1200);

  const debouncedSetUnix = useDebounce((ux,tz) => {
    // console.log(tz)
    // console.log(ux)
    setTimezone(tz)
    setUnixTimestamp(ux)
  }, 1200);

  useEffect(() => {
    const incomingDatetime = dateToUnix(date, time, timezone);
    const localDatetime = dateToUnix(localDate, localTime, timezone);
    
    if (incomingDatetime !== localDatetime) {
      setLocalDate(date);
      setLocalTime(time);
      
    }
  }, [date, time, timezone]);

//   useEffect(() => {
//     dateToUnix(localDate, localTime, timezone)
//   }, [localDate, localTime, timezone])


  const handleDateChange = useCallback((e) => {
    const newValue = e.target.value;
    setLocalDate(newValue);
    const unixTime = dateToUnix(newValue, localTime, timezone)
    if (unixTime) debouncedSetUnix(unixTime, timezone);
}, [debouncedSetUnix, localTime, timezone]);

const handleTimeChange = useCallback((e) => {
    const newValue = e.target.value;
    setLocalTime(newValue);
    const unixTime = dateToUnix(localDate, newValue, timezone)
    if (unixTime) debouncedSetUnix(unixTime, timezone);
}, [debouncedSetUnix, localDate, timezone]);

const handleTimezoneChange = useCallback((e) => {
    const newValue = e.target.value;
    //setLocalTimezone(newValue);
    setTimezone(newValue)
    const unixTime = dateToUnix(localDate, localTime, newValue)
    if (unixTime) debouncedSetUnix(unixTime, newValue);
}, [debouncedSetUnix, localDate, localTime]);

  return (
    <div>
      <div style={{display: 'flex', flexDirection: 'row', }}>
        <DateInput value={localDate} onChange={handleDateChange} />
        <div style={{width: 8}} />
        <TimeInput value={localTime} onChange={handleTimeChange} />
      </div>
      <div className="space-y-2">
        
          Timezone {timezone}
        <br />
      </div>
    </div>
  );
};

export default memo(DateSelector);