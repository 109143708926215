import React, { useRef, useEffect } from 'react';
import { useDualBirthData } from '../../contexts/birthContext';
import { useBodyViewSettings } from '../../contexts/bodyViewContext';
import { useViewData } from '../../contexts/viewContext';
import { getAllTimestamps, getPeakTransitsForTimestamp } from '../../scripts/transits';
import { bodyReference } from "../../scripts/chart";
import { ASPECT_TYPES } from "../../scripts/aspects";

const TransitList = ({ transits }) => {
  const { isBodyVisible } = useBodyViewSettings();
  const filteredTransits = transits
    .filter(transit => Math.abs(transit.orb) <= 0.8)
    .filter(transit => 
      isBodyVisible(transit.natalBody) && isBodyVisible(transit.transitBody)
    );

  return filteredTransits.length > 0 ? (
    <div style={{ fontSize: 14 }}>
      {filteredTransits.map((transit, idx) => {
        const natalBodyInfo = bodyReference[transit.natalBody];
        const transitBodyInfo = bodyReference[transit.transitBody];
        const aspectInfo = ASPECT_TYPES[transit.aspectType.toLowerCase()];
        
        if (!natalBodyInfo || !transitBodyInfo || !aspectInfo) return null;

        return (
          <div key={idx} style={{ 
            marginBottom: 4,
            display: 'flex',flexDirection: 'row',
            alignItems: 'center', justifyContent: 'center',
            gap: 8
          }}>
            <span style={{ 
              minWidth: 18, 
              color: natalBodyInfo.color, 
              textAlign: 'center',
              lineHeight: '12px'
            }} className='symbolText'>
              {natalBodyInfo.symbol}
            </span>
            <span style={{
              color: 'white',
              minWidth: 18,
              textAlign: 'center',
              lineHeight: '12px'
            }} className='symbolText'>
              {aspectInfo.symbol}
            </span>
            <span style={{
              minWidth: 18,
              color: transitBodyInfo.color,
              textAlign: 'center',
              lineHeight: '12px'
            }} className='symbolText'>
              {transitBodyInfo.symbol}
            </span>
          </div>
        );
      })}
    </div>
  ) : (
    <div style={{ 
      color: 'rgba(255,255,255,0.3)',
      fontStyle: 'italic',
      fontSize: 12,
      textAlign: 'center',
      padding: '8px 0'
    }}>
      No peak transits
    </div>
  );
};

const TransitCard = ({ timestamp, birth1Transits, birth2Transits, showSecond, }) => {
  const date = new Date(parseInt(timestamp));
  const formattedDate = date.toLocaleDateString('en-US', {
    weekday: 'short',
    month: 'short',
    day: 'numeric'
  });

  const isToday = new Date().toDateString() === date.toDateString();

  return (
    <div 
      id={`transit-${timestamp}`}
      style={{
        marginBottom: 16,
        padding: 12,
        borderRadius: 4,
        backgroundColor: 'rgba(255,255,255,0)',
        borderLeft: isToday ? '3px solid white' : '3px solid transparent'
      }}
    >
      <div style={{ 
        color: 'rgba(255,255,255,.5)',
        marginBottom: 8,
        borderBottom: '1px solid rgba(255,255,255,0.2)',
        paddingBottom: 4,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      }}>
        <span>{formattedDate}</span>
      </div>
      <div style={{
        display: 'grid',
        gridTemplateColumns: showSecond ? '1fr 1fr' : '1fr',
        gap: 16
      }}>
        <div>
          <div style={{
            color: 'rgba(255,255,255,.5)',
            fontSize: 12,
            marginBottom: 4,
            textAlign: 'center'
          }}>
            Chart 1
          </div>
          <TransitList transits={birth1Transits} />
        </div>
        {showSecond && (
          <div>
            <div style={{
              color: 'rgba(255,255,255,.5)',
              fontSize: 12,
              marginBottom: 4,
              textAlign: 'center'
            }}>
              Chart 2
            </div>
            <TransitList transits={birth2Transits} />
          </div>
        )}
      </div>
    </div>
  );
};

const TransitModule = () => {
  const { birth1, birth2 } = useDualBirthData();
  
  const { getOptions } = useViewData();
  const showSecond = getOptions().second;
  const containerRef = useRef(null);

  useEffect(() => {
    if (birth1.transits || (showSecond && birth2.transits)) {
      const timestamps = getAllTimestamps(birth1.transits || {});
      if (showSecond && birth2.transits) {
        const birth2Timestamps = getAllTimestamps(birth2.transits);
        timestamps.push(...birth2Timestamps);
      }
      const uniqueTimestamps = [...new Set(timestamps)].sort((a, b) => a - b);

      if (containerRef.current && uniqueTimestamps.length > 0) {
        const now = new Date().getTime();
        // Find the timestamp closest to now
        const closestTimestamp = uniqueTimestamps.reduce((prev, curr) => {
          return Math.abs(curr - now) < Math.abs(prev - now) ? curr : prev;
        });
        
        // Use requestAnimationFrame to ensure DOM is ready
        requestAnimationFrame(() => {
          const element = document.getElementById(`transit-${closestTimestamp}`);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        });
      }
    }
  }, [birth1.transits, birth2.transits, showSecond]);
  
  if (!birth1.transits && (!showSecond || !birth2.transits)) {
    return (
      <div style={{ 
        padding: 20,
        textAlign: 'center',
        color: 'rgba(255,255,255,0.5)'
      }}>
        {birth1.isLoadingTransits || (showSecond && birth2.isLoadingTransits) 
          ? 'Loading transits...' 
          : 'No transit data available'}
      </div>
    );
  }

  const timestamps = getAllTimestamps(birth1.transits || {});
  if (showSecond && birth2.transits) {
    const birth2Timestamps = getAllTimestamps(birth2.transits);
    timestamps.push(...birth2Timestamps);
  }
  const uniqueTimestamps = [...new Set(timestamps)].sort((a, b) => a - b);
  
  return (
    <div style={{ 
      padding: 20,
      maxHeight: '80vh',
      overflowY: 'auto'
    }} ref={containerRef}>
      <div style={{
        color: 'rgba(255,255,255,.5)',
        textAlign: 'center',
        width: '100%',
        marginBottom: 16
      }}>
        Transit Calendar
      </div>
      <div>
        {uniqueTimestamps.map(timestamp => (
          <TransitCard
            key={timestamp}
            timestamp={timestamp}
            birth1Transits={getPeakTransitsForTimestamp(birth1.transits || {}, timestamp)}
            birth2Transits={getPeakTransitsForTimestamp(birth2.transits || {}, timestamp)}
            showSecond={showSecond}
          />
        ))}
      </div>
    </div>
  );
};

export default TransitModule;
