import { createContext, useContext, useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { 
  getAuth, 
  signInWithPopup, 
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  onAuthStateChanged 
} from 'firebase/auth';
import { getUser, updateUser } from '../api/trine-backend.api';

// Initialize Firebase
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchUserData = async (userId) => {
    try {
      const data = await getUser(userId);
      setUserData(data);
    } catch (error) {
      console.error("Error fetching user data:", error);
      // If user doesn't exist in backend, create them
      if (error.status === 404 && user) {
        try {
          const newUserData = await updateUser(userId, {
            email: user.email,
            createdAt: new Date(),
            updatedAt: new Date(),
            profiles: []
          });
          setUserData(newUserData);
        } catch (createError) {
          console.error("Error creating user in backend:", createError);
        }
      }
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      if (user) {
        fetchUserData(user.uid);
      } else {
        setUserData(null);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      await fetchUserData(result.user.uid);
      return result.user;
    } catch (error) {
      console.error("Error signing in with Google:", error);
      throw error;
    }
  };

  const signInWithEmail = async (email, password) => {
    try {
      const result = await signInWithEmailAndPassword(auth, email, password);
      await fetchUserData(result.user.uid);
      return result.user;
    } catch (error) {
      console.error("Error signing in with email:", error);
      throw error;
    }
  };

  const signUpWithEmail = async (email, password) => {
    try {
      const result = await createUserWithEmailAndPassword(auth, email, password);
      await fetchUserData(result.user.uid);
      return result.user;
    } catch (error) {
      console.error("Error signing up with email:", error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
      setUserData(null);
    } catch (error) {
      console.error("Error signing out:", error);
      throw error;
    }
  };

  const updateUserData = async (data) => {
    if (!user) return;
    try {
      const updatedData = await updateUser(user.uid, {
        ...data,
        updatedAt: new Date()
      });
      setUserData(updatedData);
      return updatedData;
    } catch (error) {
      console.error("Error updating user data:", error);
      throw error;
    }
  };

  const saveProfile = async (name, location, unixTimestamp) => {
    if (!user || !userData) return;
    
    const newProfile = {
      name,
      location: {
        latitude: location.latitude,
        longitude: location.longitude
      },
      // timestamp: new Date(timestamp),
      unixTimestamp, // Convert milliseconds to seconds
    };

    const profiles = userData.profiles || [];
    const updatedProfiles = [...profiles, newProfile];

    try {
      const updatedData = await updateUser(user.uid, {
        ...userData,
        profiles: updatedProfiles,
        updatedAt: new Date()
      });
      setUserData(updatedData);
      return updatedData;
    } catch (error) {
      console.error("Error saving profile:", error);
      throw error;
    }
  };

  const deleteProfile = async (profileName) => {
    if (!user || !userData || !userData.profiles) return;

    const updatedProfiles = userData.profiles.filter(p => p.name !== profileName);

    try {
      const updatedData = await updateUser(user.uid, {
        ...userData,
        profiles: updatedProfiles,
        updatedAt: new Date()
      });
      setUserData(updatedData);
      return updatedData;
    } catch (error) {
      console.error("Error deleting profile:", error);
      throw error;
    }
  };

  const value = {
    user,
    userData,
    loading,
    signInWithGoogle,
    signInWithEmail,
    signUpWithEmail,
    logout,
    updateUserData,
    saveProfile,
    deleteProfile
  };

  return (
    <UserContext.Provider value={value}>
      {!loading && children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
