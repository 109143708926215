import React, { useEffect, useState } from 'react';
import { useDualBirthData } from '../../contexts/birthContext';
import { findExactTransits } from './calculateTransits';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    padding: '16px',
  },
  dayCard: {
    border: '1px solid rgba(255, 255, 255, 0.1)',
    borderRadius: '8px',
    padding: '16px',
  },
  date: {
    textAlign: 'center',
    fontSize: '18px',
    marginBottom: '16px',
    color: '#fff'
  },
  aspectsContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '12px',
    flexWrap: 'wrap'
  },
  aspectBox: {
    padding: '8px 16px',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    fontSize: '16px'
  }
};

const aspectColors = {
  conjunction: { backgroundColor: '#48BB78', color: 'white' },
  opposition: { backgroundColor: '#E53E3E', color: 'white' },
  trine: { backgroundColor: '#4299E1', color: 'white' },
  square: { backgroundColor: '#ED8936', color: 'white' },
  sextile: { backgroundColor: '#9F7AEA', color: 'white' }
};

const PLANET_SYMBOLS = {
  sun: '☉',
  moon: '☽',
  mercury: '☿',
  venus: '♀',
  mars: '♂',
  jupiter: '♃',
  saturn: '♄',
  uranus: '♅',
  neptune: '♆',
  pluto: '♇'
};

const AspectBox = ({ transitBody, aspect, natalBody, orb }) => (
  <div style={{ ...styles.aspectBox, ...aspectColors[aspect] }}>
    {PLANET_SYMBOLS[transitBody]} ☌ {PLANET_SYMBOLS[natalBody]} ({orb.toFixed(2)}°)
  </div>
);

const DayCard = ({ date, aspects }) => (
  <div style={styles.dayCard}>
    <div style={styles.date}>
      {date.toLocaleDateString('en-US', { 
        month: 'short', 
        day: 'numeric'
      })}
    </div>
    <div style={styles.aspectsContainer}>
      {aspects.map((aspect, index) => (
        <AspectBox key={index} {...aspect} />
      ))}
    </div>
  </div>
);

const TransitDays = () => {
  const { birth1 } = useDualBirthData();
  console.log(birth1)
  const [transitDays, setTransitDays] = useState([]);

  // useEffect(() => {
  //   if (!birth1.unixTimestamp) return;
    
  //   const exactTransits = findExactTransits(birth1);
    
  //   // Group transits by date
  //   const groupedTransits = exactTransits.reduce((acc, transit) => {
  //     const dateKey = transit.date.toDateString();
  //     if (!acc[dateKey]) {
  //       acc[dateKey] = {
  //         date: transit.date,
  //         aspects: []
  //       };
  //     }
  //     acc[dateKey].aspects.push(transit);
  //     return acc;
  //   }, {});

  //   setTransitDays(Object.values(groupedTransits));
  // }, [birth1.unixTimestamp]);

  return (
    <div style={styles.container}>
      {transitDays.map((day, index) => (
        <DayCard key={index} {...day} />
      ))}
    </div>
  );
};

export default TransitDays;