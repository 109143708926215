import React, { createContext, useContext, useState } from 'react';
import { bodyReference } from '../scripts/chart';

const BodyViewContext = createContext(null);

export const BodyViewProvider = ({ children }) => {


  const [viewState, setViewState] = useState(() => {
    const initialState = {};
    Object.keys(bodyReference).forEach((body) => {
      initialState[body] = true; // Default visibility is true
    });
    return initialState;
  });


  const isBodyVisible = name => !!viewState[name]

  const setBodyVisibility = (bodyName, isVisible) => setViewState((prevState) => ({
      ...prevState,
      [bodyName]: isVisible,
    }))

  const toggleBodyVisibility = (bodyName) => setViewState((prevState) => ({
      ...prevState,
      [bodyName]: !prevState[bodyName],
    }));

    const value = {
      viewState,
      isBodyVisible,
      setBodyVisibility,
      toggleBodyVisibility
    }
  return <BodyViewContext.Provider value={value}>{children}</BodyViewContext.Provider>;
};

export const useBodyViewSettings = () => {
  const context = useContext(BodyViewContext);
  if (!context) throw new Error('useLineView must be used within BodyViewProvider');
  return context;
};

export default BodyViewProvider;