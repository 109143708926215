import React, {useCallback, useEffect, useMemo, useState} from 'react'
import { isEqual } from 'lodash';
import Draggable from 'react-draggable'; // The default
import './App.css';

//import { bodies_default_naia } from './data/people';
import GlobeMap from './components/Globe';
import OptionsPane from './components/modalsmenus/OptionsPane';
import {  dateToUnix, unixToDate, parseFloatSafe, getTimezoneFromCoordinates, } from './scripts/helpers';
import { DataForm } from './components/birthdata/DataForm';
import ZodiacWheel, { calculateAspectsBetweenSets } from './components/ZodiacWheel';
import { addNameKeyToAspects, findRelatedAspects } from './scripts/aspects';
import AspectModal from './components/aspects/AspectModal';
import useSyncWithQueryParams from './hooks/useSyncWithQueryParams';
import FloatingModal from './components/modalsmenus/FloatingModal';
import { RelocForm } from './components/RelocForm';
import { buildChart, calculateAsteroids } from './api/trine-backend.api';
import MobileUIModal from './components/modalsmenus/MobileUIModal';
import { useDualBirthData } from './contexts/birthContext';
import { useViewData } from './contexts/viewContext';
import { useBodiesData } from './contexts/bodiesContext';
import SidebarMain from './components/modalsmenus/sidebars/SidebarMain';
import SidebarRelocationTransits from './components/modalsmenus/sidebars/SidebarRelocationTransits';
import { useRelocation } from './contexts/relocationPathsContext';

function App() {
  const {
    birth1,
    birth2,
    getTimezoneNo, 
    setTimezoneNo,
    getUnixTimestampNo,
    setUnixTimestampNo,
    getCoordinatesNo,
    setCoordinatesNo,
    setCoordinatesNoValue
   } = useDualBirthData();

   const { timezone, unixTimestamp, coordinates } = birth1;
   const timezone2 = birth2.timezone
   const unixTimestamp2 = birth2.unixTimestamp
   const coordinates2 = birth2.coordinates
  
  const {
    bodies,
    bodies2,
    aspects,
    cusps,
    parts,
    angleBodies,
    setBodiesNo,
    getBodiesNo,
    setAngleBodiesNo,
    getAngleBodiesNo,
    setCuspsNo,
    getCuspsNo,
    setAspectsNo,
    getAspectsNo,
    setPartsNo,
    getPartsNo,
    setAsteroidsNo,
    getAsteroidsNo,
  } = useBodiesData();

  const [relocation, setRelocation] = useState({})
  const [toggleAspects, setToggleAspects] = useState(false)

  const {
    getIsMobile,
    setIsMobile,
    getModalOpen,
    setModalOpen,
    getActiveTab,
    setActiveTab,
    getModalOpenAspect,
    setModalOpenAspect,
    getActiveTabAspect,
    setActiveTabAspect,
    getLineSidebarOpen,
    setLineSidebarOpen,
    setRelocTransitTab,
    getOptions,
    setOptionKey,
  } = useViewData();

  const {
    relocPaths,
    updateRelocPaths,
    clearRelocPaths
} = useRelocation();

    // Define state variables to sync with query params
    const stateVars = useMemo(() => {
      return [
        birth1.unixTimestamp,
        birth2.unixTimestamp,
        birth1.timezone,
        birth2.timezone,
        getOptions().trines,
        getOptions().sextiles,
        getOptions().squares,
        getOptions().parans,
        getOptions().second,
        getOptions().synastry,
        getOptions().relocate,
        relocation.lat,
        relocation.lng,
        birth1.coordinates.latitude, birth1.coordinates.longitude, birth2.coordinates.latitude, birth2.coordinates.longitude,
        getModalOpen(), getModalOpenAspect(), getActiveTab(), getActiveTabAspect()
      ]
    }, [unixTimestamp, unixTimestamp2, timezone, timezone2, getOptions(), relocation, coordinates, coordinates2, getModalOpen(), getModalOpenAspect(), getActiveTab(), getActiveTabAspect()]);
    
    const setters = useMemo(() => [
      (ts) => setUnixTimestampNo(Number(ts), 1),
      (ts) => setUnixTimestampNo(Number(ts), 2),
      (tz) => setTimezoneNo(tz, 1),
      (tz) => setTimezoneNo(tz, 2),
      (value) => setOptionKey('trines', value),
      (value) => setOptionKey('sextiles', value),
      (value) => setOptionKey('squares', value),
      (value) => setOptionKey('parans', value),
      (value) => setOptionKey('second', value),
      (value) => setOptionKey('synastry', value),
      (value) => setOptionKey('relocate', value),
      (lat) => setRelocation(prev => ({ ...prev, lat: lat !== null ? parseFloatSafe(lat, null) : null })),
      (lng) => setRelocation(prev => ({ ...prev, lng: lng !== null ? parseFloatSafe(lng, null) : null })),
      (lat) => setCoordinatesNoValue(lat, "latitude", 1),
      (lng) => setCoordinatesNoValue(lng, "longitude", 1),
      (lat) => setCoordinatesNoValue(lat, "latitude", 2),
      (lng) => setCoordinatesNoValue(lng, "longitude", 2),
      setModalOpen,            // Corrected from modalOpen
      setModalOpenAspect,     // Corrected from modalOpenAspect
      setActiveTab,           // Correctly referencing the setter
      setActiveTabAspect      // Correctly referencing the setter
    ], []);

    const queryParamKeys = useMemo(() => [
      'unixTimestamp1',
      'unixTimestamp2',
      'timezone',
      'timezone2',
      'trines',
      'sextiles',
      'squares',
      'parans',
      'second',
      'syn',
      'relocate',
      'relocationLat',
      'relocationLng',
      'c1lat',
      'c1lng',
      'c2lat',
      'c2lng', 'mch','ma','at','ata'
    ], []);
    
  
    // Use the custom hook to synchronize state with URL
    useSyncWithQueryParams(stateVars, setters, queryParamKeys, 300);

    const resetBirthData = (n) => {
      setCoordinatesNo({latitude: null, longitude: null}, n)
      setBodiesNo([], n)
      setUnixTimestampNo(new Date().getTime(), n)
      setAngleBodiesNo([],n)
      setCuspsNo([],n)
      setAspectsNo([], n)
      setPartsNo([], n)
    }

  const createFormProps = n => ({
      date: unixToDate(getUnixTimestampNo(n), getTimezoneNo(n)).dateString,
      time: unixToDate(getUnixTimestampNo(n), getTimezoneNo(n)).timeString,
      timezone: getTimezoneNo(n), setTimezone: (tz) => setTimezoneNo(tz, n),
      unixTimestamp: getUnixTimestampNo(n),
      setUnixTimestamp: (ts) => setUnixTimestampNo(ts, n),
      setDate: (newDate) => setUnixTimestampNo(dateToUnix(newDate, unixToDate(getUnixTimestampNo(n), getTimezoneNo(n)).timeString, getTimezoneNo(n)), n),
      setTime: (newTime) => setUnixTimestampNo(dateToUnix(unixToDate(getUnixTimestampNo(n), getTimezoneNo(n)).dateString, newTime, getTimezoneNo(n)), n),
      latitude: getCoordinatesNo(n).latitude,
      longitude: getCoordinatesNo(n).longitude,
      setLatitude: (lat) => setCoordinatesNoValue(lat, "latitude", n),
      setLongitude: (lng) => setCoordinatesNoValue(lng, "longitude", n),
      resetBirthData, index: n,
    })

  const [synastryAspects, setSynastryAspects] = useState([])
  const [relocSynastryAspects, setRelocSynastryAspects] = useState([])
  const [selectedAspect, setSelectedAspect] = useState(null)


  const [aspectSearch, setAspectSearch] = useState([]);
  const [processedAspects, setProcessedAspects] = useState({});
  const getPAspectsNo = n => processedAspects[n]?processedAspects[n] : aspects[n]?aspects[n] : []
  
  const handleResize = useCallback(() => {
    setIsMobile(window.innerWidth <= 768);
  }, []);
  
  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);


  useEffect(() => {
      const newAspects = {};
      Object.keys(aspects).forEach((key) => {
          newAspects[key] = addNameKeyToAspects(getAspectsNo(key), {
              point1Source: parseInt(key), 
              point2Source: parseInt(key)
          });
      });
      setProcessedAspects(newAspects);
  }, [aspects])

  useEffect(() => {
    try {
        if (selectedAspect && processedAspects[1] && processedAspects[2] && synastryAspects) {
            const asps = findRelatedAspects(selectedAspect, [
                ...processedAspects[1], 
                ...processedAspects[2], 
                ...synastryAspects,
            ])
            // console.log(selectedAspect)
            // console.log(asps)
            
            setAspectSearch(asps);
        } else if (!selectedAspect) {
          setAspectSearch([])
        }
    } catch(e) {
        console.log("ERROR IN ASPECTSEARCH", e);
    }
  }, [selectedAspect, processedAspects, synastryAspects]);


  const unixTimestamps = useMemo(() => [birth1.unixTimestamp, birth2.unixTimestamp], [birth1.unixTimestamp, birth2.unixTimestamp]);
  //const bodiesSelection = useMemo(() => [[...getBodiesNo(1), ...getPartsNo(1)], [...getBodiesNo(2), ...getPartsNo(2)]], [bodies, bodies2, parts]);
  const bodiesSelection = useMemo(() => {
    const selection1 = getBodiesNo(1).concat(getPartsNo(1));
    const selection2 = getBodiesNo(2).concat(getPartsNo(2));
    return [selection1, selection2];
  }, [bodies, bodies2, parts], isEqual); // Use deep comparison
  

  useEffect(() => {
    setSynastryAspects(calculateAspectsBetweenSets([...bodies, ...getAngleBodiesNo(1), ...getAsteroidsNo(1)], [...bodies2, ...getAngleBodiesNo(2), ...getAsteroidsNo(2)], 1, 2));
    setRelocSynastryAspects(calculateAspectsBetweenSets([...bodies, ...getAngleBodiesNo(3)], [...bodies2, ...getAngleBodiesNo(4)], 3, 4));
  }, [bodies, angleBodies, bodies2, relocation])

  const makeChart = async (latitude, longitude, timestamp, tz, n) => {
    const allDataResponse = await buildChart(latitude, longitude, timestamp, tz);
    // console.log(allDataResponse)
    const { chartBodies, chartCusps, chartAspects, chartAngles, chartParts, chartNonPlanets } = allDataResponse;
    
    const asteroids = await calculateAsteroids(timestamp);
    setAsteroidsNo(asteroids, n);
    
    const astAsps = calculateAspectsBetweenSets(chartBodies,asteroids, n,n);
    const newBodies = chartBodies.map(body => ({ ...body, strokeWidth: 2 }));
    
    setCuspsNo(chartCusps, n);
    setAngleBodiesNo([...chartAngles], n);
    setAspectsNo([...chartAspects, ...astAsps], n);
    setPartsNo(chartParts, n);
    setBodiesNo([...newBodies, ...chartNonPlanets], n);
}

  useEffect(() => {
    if (!unixTimestamp || !birth1.coordinates.latitude || !birth1.coordinates.longitude || !birth1.timezone || isNaN(birth1.coordinates.latitude) ||isNaN(birth1.coordinates.longitude)) return;
    makeChart(birth1.coordinates.latitude, birth1.coordinates.longitude, birth1.unixTimestamp, birth1.timezone, 1);
  }, [birth1.unixTimestamp, birth1.coordinates.latitude, birth1.coordinates.longitude, birth1.timezone])

  useEffect(() => {
    if (!birth2.unixTimestamp || !birth2.coordinates.latitude || !birth2.coordinates.longitude || !birth2.timezone || isNaN(birth2.coordinates.latitude) ||isNaN(birth2.coordinates.longitude)  ) return;
    makeChart(birth2.coordinates.latitude, birth2.coordinates.longitude, birth2.unixTimestamp, birth2.timezone, 2);
  }, [birth2.unixTimestamp, birth2.coordinates.latitude, birth2.coordinates.longitude, birth2.timezone])

const calculateRelocs = async (timestamp, loc, n) => {
  if (isNaN(loc.lat) || isNaN(loc.lng)) return;
  const tz = await getTimezoneFromCoordinates(loc.lat, loc.lng);
  await makeChart(loc.lat, loc.lng, getUnixTimestampNo(n), tz, n+2);
  
}

useEffect(() => {
  [1,2].map(n => {
    if (getUnixTimestampNo(n) && relocation) calculateRelocs(getUnixTimestampNo(n), relocation, n)  
  })
}, [ birth1.unixTimestamp,  birth2.unixTimestamp, relocation.lat, relocation.lng])


  // Organize wheel configurations
  const wheelConfigs = useMemo(() => {
    const options = getOptions();
    return [
      {
        bodies: [...bodies, ...getAngleBodiesNo(1), ...getPartsNo(1)],
        cusps: getCuspsNo(1),
        aspects: getPAspectsNo(1),
        ascDeg: getAngleBodiesNo(1)[0]?.degree,
        asteroids: getAsteroidsNo(1),
        title: 'Chart 1',
      },
      {
        bodies: [...bodies2, ...getAngleBodiesNo(2), ...getPartsNo(2)],
        cusps: getCuspsNo(2),
        aspects: getPAspectsNo(2),
        ascDeg: getAngleBodiesNo(2)[0]?.degree,
        asteroids: getAsteroidsNo(2),
        title: 'Chart 2',
      },
      {
        bodies: [...bodies, ...getAngleBodiesNo(3), ...getPartsNo(3)],
        cusps: getCuspsNo(3),
        aspects: getPAspectsNo(3),
        ascDeg: getAngleBodiesNo(3)[0]?.degree,
        title: 'Relocated Chart 1',
      },
      {
        bodies: [...bodies2, ...getAngleBodiesNo(4), ...getPartsNo(4)],
        cusps: getCuspsNo(4),
        aspects: getPAspectsNo(4),
        ascDeg: getAngleBodiesNo(4)[0]?.degree,
        title: 'Relocated Chart 2',
      },
      {
        bodies: [...bodies, ...getAngleBodiesNo(1), ...getPartsNo(1)],
        cusps: getCuspsNo(1),
        aspects: getPAspectsNo(1),
        ascDeg: getAngleBodiesNo(1)[0]?.degree,
        synastry: {bodies: [...bodies2, ...getPartsNo(2)], aspects: getPAspectsNo(2), angleBodies: getAngleBodiesNo(2), cusps: getCuspsNo(2), metadata: {}, synastryAspects}
      },
      {
        bodies: [...bodies, ...getAngleBodiesNo(3), ...getPartsNo(3)],
        cusps: getCuspsNo(3),
        aspects: getPAspectsNo(3),
        ascDeg: getAngleBodiesNo(3)[0]?.degree,
        synastry: {bodies: [...bodies2, ...getPartsNo(4)], aspects: getPAspectsNo(4), angleBodies: getAngleBodiesNo(4), cusps: getCuspsNo(4), metadata: {}, synastryAspects:relocSynastryAspects}
      },
      // Add the other 4 configurations...
    ].map(config => (
      config.synastry ? (
        <div style={{ marginTop: -8, padding: 0, borderRadius: 320 }}>
          <ZodiacWheel {...config} size={300} selectedAspect={selectedAspect} aspectSearch={aspectSearch} setSelectedAspect={setSelectedAspect} />
        </div>
      ) : (
        <ZodiacWheel {...config} size={300} selectedAspect={selectedAspect} aspectSearch={aspectSearch} setSelectedAspect={setSelectedAspect} />
      )
    ))
  }, [bodies, bodies2, angleBodies, cusps, aspects, processedAspects, synastryAspects, relocSynastryAspects, aspectSearch, selectedAspect, getOptions()]);

  const aspectPaneProps = {aspects, getAspectsNo, selectedAspect, setSelectedAspect, synastryAspects, relocSynastryAspects, aspectSearch, processedAspects}
  const mobileUIModalProps = {isOpen: getModalOpen(), setIsOpen: setModalOpen, wheels: wheelConfigs, activeUITab: getActiveTab(), setActiveUITab: setActiveTab, aspectPaneProps, isMobile: getIsMobile()}

  const options = getOptions();
  useEffect(() => {
    if (getOptions().relocate && !getLineSidebarOpen()) {
      setRelocTransitTab('relocation')
      setLineSidebarOpen(true)
    }
  }, [getOptions().relocate])
  
  return (
    <div className="App">
      
      <div style={{position: 'relative', overflow: 'hidden'}}>
            <GlobeMap unixTimestamps={unixTimestamps}  
              bodiesSelection={bodiesSelection} onGlobeClick={e => setRelocation(e)} relocation={relocation} setRelocation={setRelocation} />

            {/* {getIsMobile() &&<MobileModal {...mobileModalProps}/>} */}
            <div style={{position: 'absolute', top: 0, left: 0, fontSize: 10, color: 'white', zIndex: 800}}>
              <div style={{display: 'flex', flexDirection: getIsMobile() ? 'column' : 'row', }}>
              <DataForm {...createFormProps(1)} onChangeLocation={(locationObj) => {
                if (locationObj && locationObj.geometry) {
                  const loc = locationObj.geometry.location
                  
                  setCoordinatesNo({latitude: loc.lat(), longitude: loc.lng(), lat: loc.lat(), lng: loc.lng()}, 1)
                }
              }} />
              {getOptions().second && <DataForm {...createFormProps(2)} onChangeLocation={(locationObj) => {
                if (locationObj && locationObj.geometry) {
                  const loc = locationObj.geometry.location
                  
                  setCoordinatesNo({latitude: loc.lat(), longitude: loc.lng(), lat: loc.lat(), lng: loc.lng()}, 2)
                }
              }} />} 
              {getOptions().relocate && <RelocForm relocation={relocation} setRelocation={setRelocation} />}
              </div>
              <div style={{display: 'flex', flexDirection: 'row', }}>
              
              <Draggable>
              <div style={{pointerEvents: 'none'}}>
              <FloatingModal title="Chart 1" hideUI={getIsMobile()}>
                <div>{wheelConfigs[0]}</div></FloatingModal>
                </div>
              </Draggable>

          
               {getOptions().second && !getIsMobile() && 
               <Draggable>
              <div style={{pointerEvents: 'none'}}>
               <FloatingModal title="Chart 2" hideUI={getIsMobile()}>
               <div>{wheelConfigs[1]}</div></FloatingModal>
               </div></Draggable>
               }
               </div>
                
            </div>
            <div style={{position: 'absolute', transition:'.5s all', top: 0, right: (getOptions().relocate && getLineSidebarOpen()) ? 280 : 0, fontSize: 10, color: 'white', zIndex: 800}}>
              {getOptions().second && !getIsMobile() &&  
              <Draggable>
              <div style={{pointerEvents: 'none'}}>
              <FloatingModal title="Synastry 1&2" hideUI={getIsMobile()} size={480} ><div style={{ marginTop: 16, padding: 8, borderRadius: 320 }}>
                  {wheelConfigs[4]}
                  </div></FloatingModal>
                  </div></Draggable>
                  }
             
               
            </div>
            <div className="relocSynDesk" style={{position: 'absolute', transition:'.5s all', bottom: 0, right: (getOptions().relocate && getLineSidebarOpen()) ? 280 : 0, fontSize: 10, color: 'white'}}>
            {getOptions().relocate && relocation && !getIsMobile() && (getOptions().second ? 
            <Draggable>
              <div style={{pointerEvents: 'none'}}>
                <FloatingModal title="Relocated Synastry 1&2" hideUI={getIsMobile()} size={480} ><div style={{ marginTop: 16, padding: 8, borderRadius: 320 }}>
              {wheelConfigs[5]}</div></FloatingModal>
              </div></Draggable> : null
            )}</div>
            {getOptions().relocate && relocation && !getIsMobile() && 
            <div className="relocationDesk" style={{position: 'absolute', bottom: 0, left: 0, fontSize: 10, color: 'white', pointerEvents: 'none'}}>
              
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'flex-end', pointerEvents: 'none'}}>
              
              <div style={{pointerEvents: 'auto'}}>
                       <Draggable>
                        <div style={{pointerEvents: 'none'}}>
                        {!getIsMobile() &&   <FloatingModal title={`Relocated 1 - ${Math.floor(relocation.lat*1000)/1000},${Math.floor(relocation.lng*1000)/1000}`} hideUI={getIsMobile()} >
                      {wheelConfigs[2]}
                    </FloatingModal>}
                    </div></Draggable>

                  </div>
                  <div style={{pointerEvents: 'auto'}}>
                    {getOptions().second && !getIsMobile() && <Draggable>
                      <div style={{pointerEvents: 'none'}}>
                    <FloatingModal title={`Relocated 2 - ${Math.floor(relocation.lat*1000)/1000},${Math.floor(relocation.lng*1000)/1000}`} hideUI={getIsMobile()} >
                    {wheelConfigs[3]}
                    </FloatingModal></div></Draggable>}
                    
                  </div>
              </div>
            </div>}


            {/* <div style={{position: 'absolute', transition: '.3s all', display: 'flex', flexDirection: 'column', alignItems: 'center', bottom: toggleAspects ? 0 : -400, width: '100%', fontSize: 10, color: 'white', zIndex: 50}}>
              <button 
                    onClick={() => setToggleAspects(!toggleAspects)}
                    style={{
                        marginBottom: 16,
                        padding: '4px 12px',
                        backgroundColor: 'rgba(255,68,68,0.2)',
                        border: '1px solid #ff4444',
                        borderRadius: 4,
                        color: 'white',
                        cursor: 'pointer'
                    }}
                >
                    SHOW/HIDE ASPECTS
                </button>
            </div> */}
                {!getIsMobile() && <AspectModal
                  aspectPaneProps={aspectPaneProps}
                  isOpen={getModalOpenAspect()}
                  setIsOpen={setModalOpenAspect}
                  activeTab={getActiveTabAspect()}
                  setActiveTab={setActiveTabAspect}
                  isMobile={getIsMobile()}
                /> }
                {getIsMobile() && <MobileUIModal 
                  {...mobileUIModalProps}
                />}
            
            <OptionsPane /> 
            
            <SidebarRelocationTransits wheels={wheelConfigs}/> 
              <SidebarMain />
          </div>
    </div>
    );
}
export default App;
