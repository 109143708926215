import React, { useState, useEffect } from 'react';
import Autocomplete from "react-google-autocomplete";
import DateSelector from './DateSelector';
import { getTimezoneFromCoordinates, dateToUnix, unixToDate } from '../../scripts/helpers';
import { useUser } from '../../contexts/userContext';

export const DataForm = React.memo(({ onChangeLocation, date, setDate, time, timezone, setTimezone, setTime, unixTimestamp, setUnixTimestamp, placesValue, resetBirthData, index, latitude, longitude }) => {
    const { userData, saveProfile, deleteProfile } = useUser();
    const [showProfileMenu, setShowProfileMenu] = useState(false);
    const [showSaveForm, setShowSaveForm] = useState(false);
    const [profileName, setProfileName] = useState('');

    const dateProps = {
        date, setDate, time, timezone, setTimezone, setTime, setUnixTimestamp
    };

    // Check if current data matches any existing profile
    const currentDataMatchesProfile = userData?.profiles?.some(profile => 
        profile.location.latitude === latitude &&
        profile.location.longitude === longitude &&
        profile.unixTimestamp === unixTimestamp
    );

    const handleLoadProfile = (profile) => {
        const newTimezone = getTimezoneFromCoordinates(profile.location.latitude, profile.location.longitude);
        setTimezone(newTimezone);
        onChangeLocation({
            geometry: {
                location: {
                    lat: () => profile.location.latitude,
                    lng: () => profile.location.longitude
                }
            }
        });
        
        
        const dateObject = unixToDate(profile.unixTimestamp, newTimezone)
        const newDate = dateObject.dateString;
        const newTime = dateObject.timeString;
        setDate(newDate);
        setTime(newTime);
        // Calculate and set the unix timestamp
        setUnixTimestamp(profile.unixTimestamp);
        setShowProfileMenu(false);
    };

    const handleDeleteProfile = async (profileName, e) => {
        e.stopPropagation(); // Prevent triggering the profile load
        try {
            await deleteProfile(profileName);
        } catch (error) {
            console.error('Error deleting profile:', error);
        }
    };

    const handleSaveProfile = async () => {
        if (!profileName.trim()) return;

        try {
            await saveProfile(
                profileName,
                { latitude, longitude },
                unixTimestamp
            );
            setShowSaveForm(false);
            setProfileName('');
        } catch (error) {
            console.error('Error saving profile:', error);
        }
    };

    const handleCancelSave = () => {
        setShowSaveForm(false);
        setProfileName('');
    };

    if (showSaveForm) {
        return (
            <div style={{border: '1px solid white', padding: 4, maxWidth: 180}}>
                <div style={{display: 'flex', gap: '4px', alignItems: 'center'}}>
                    <input
                        type="text"
                        value={profileName}
                        onChange={(e) => setProfileName(e.target.value)}
                        placeholder="Profile name"
                        style={{
                            flex: 1,
                            padding: '4px',
                            backgroundColor: 'rgba(255,255,255,0.1)',
                            border: '1px solid rgba(255,255,255,0.2)',
                            borderRadius: 4,
                            color: 'white',
                            fontSize: 12
                        }}
                    />
                    <button
                        onClick={handleSaveProfile}
                        style={{
                            padding: '2px 4px',
                            backgroundColor: 'rgba(255,255,255,0.2)',
                            border: '1px solid rgba(255,255,255,0.3)',
                            borderRadius: 4,
                            color: 'white',
                            cursor: 'pointer',
                            fontSize: 12
                        }}
                    >
                        ✔
                    </button>
                    <button
                        onClick={handleCancelSave}
                        style={{
                            padding: '2px 4px',
                            backgroundColor: 'rgba(255,68,68,0.2)',
                            border: '1px solid #ff4444',
                            borderRadius: 4,
                            color: 'white',
                            cursor: 'pointer',
                            fontSize: 12
                        }}
                    >
                        ✕
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div style={{border: '1px solid white', padding: 4, maxWidth: 180, position: 'relative'}}>
            <div style={{display: 'flex', alignItems: 'center', gap: '4px'}}>
                {userData && (
                    <button
                        onClick={() => setShowProfileMenu(!showProfileMenu)}
                        style={{
                            fontSize: 10,
                            padding: '2px 4px',
                            backgroundColor: 'rgba(255,255,255,0.2)',
                            border: '1px solid rgba(255,255,255,0.5)',
                            borderRadius: 4,
                            color: 'white',
                            cursor: 'pointer'
                        }}
                    >
                        ↓
                    </button>
                )}
                <Autocomplete
                    style={{maxWidth: 120, fontSize: 12}}
                    apiKey={'AIzaSyAmyqW2CPg2LeXUDSpwaECD2rqHfLi7lyE'}
                    defaultValue={placesValue}
                    onPlaceSelected={(place) => {
                        if (place) {
                            const tz = getTimezoneFromCoordinates(place.geometry.location.lat(), place.geometry.location.lng());
                            setTimezone(tz);    
                            onChangeLocation(place);
                        }
                    }}
                />
            </div>

            {/* Profile Menu Popup */}
            {showProfileMenu && userData?.profiles && (
                <div style={{
                    position: 'absolute',
                    top: '100%',
                    left: 0,
                    backgroundColor: 'rgba(0,0,0,0.9)',
                    border: '1px solid rgba(255,255,255,0.2)',
                    borderRadius: 4,
                    padding: 4,
                    zIndex: 1000,
                    maxHeight: 200,
                    overflowY: 'auto',
                    minWidth: '100%'
                }}>
                <div style={{
                    borderTop: '1px solid rgba(255,255,255,0.2)',
                    marginTop: 4,
                    paddingTop: 4,
                    textAlign: 'center',
                    
                }}>
                    <button
                        onClick={() => setShowProfileMenu(false)}
                        style={{
                            padding: '2px 8px',
                            backgroundColor: 'rgba(255,255,255,0.2)',
                            border: '1px solid rgba(255,255,255,0.3)',
                            borderRadius: 4,
                            color: 'white',
                            cursor: 'pointer',
                            fontSize: 12
                        }}
                    >
                        Cancel
                    </button>
                </div>
                    {userData.profiles.map((profile, i) => (
                        <div
                            key={i}
                            onClick={() => handleLoadProfile(profile)}
                            style={{
                                padding: '2px 4px',
                                cursor: 'pointer',
                                color: 'white',
                                fontSize: 12,
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                ':hover': {
                                    backgroundColor: 'rgba(255,255,255,0.1)'
                                }
                            }}
                        >
                            <span>{profile.name}</span>
                            <button
                                onClick={(e) => handleDeleteProfile(profile.name, e)}
                                style={{
                                    padding: '2px 4px',
                                    backgroundColor: 'rgba(255,68,68,0.2)',
                                    border: '1px solid #ff4444',
                                    borderRadius: 4,
                                    color: 'white',
                                    cursor: 'pointer',
                                    fontSize: 10,
                                    marginLeft: 4
                                }}
                            >
                                ✕
                            </button>
                        </div>
                    ))}
                </div>
            )}

            <DateSelector {...dateProps} />

            <div style={{display: 'flex', gap: '4px', position: 'absolute', top: 4, right: 4}}>
                {userData && !currentDataMatchesProfile && (
                    <button
                        onClick={() => setShowSaveForm(true)}
                        style={{
                            fontSize: 10,
                            padding: '2px 4px',
                            backgroundColor: 'rgba(255,255,255,0.2)',
                            border: '1px solid rgba(255,255,255,0.3)',
                            borderRadius: 4,
                            color: 'white',
                            cursor: 'pointer'
                        }}
                    >
                        💾
                    </button>
                )}
                <button 
                    onClick={() => resetBirthData(index)}
                    style={{
                        fontSize: 10,
                        padding: '2px 4px',
                        backgroundColor: 'rgba(255,244,244,0.2)',
                        border: '1px solid #ff4444',
                        borderRadius: 4,
                        color: 'white',
                        cursor: 'pointer'
                    }}
                >
                    ✕
                </button>
            </div>
        </div>
    );
});