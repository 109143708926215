import { useState } from "react";
import { getAspect } from "../../api/trine-backend.api";
import { ASPECT_TYPES, } from "../../scripts/aspects"
import { bodyReference } from "../../scripts/chart";

function truncateAtNWords(input, n) {
    const words = input.split(/\s+/); 
    if (words.length <= n) {
        return input; 
    }
    return words.slice(0, n).join(' ') + '...';
}


export const AspectItem = ({ aspect, setSelectedAspect, isSearchResult, isSelected, isMobile }) => {

    const [aspectInterpretation, setAspectInterpretation] = useState(false)
    // Object.entries(ASPECT_TYPES).forEach(([aspectKey, definition]) => {
    //     aspect orb < definition.orb

    const getAspectColor = (aspectKey) => {
        if (ASPECT_TYPES[aspectKey])
            return ASPECT_TYPES[aspectKey].color || "white";
        return "white"
        
    };

    const getBodySymbol = (bodyName) => {
        const body = bodyReference[bodyName];
        if (!body) return bodyName; // fallback to name if not found
        return {
            symbol: body.symbol,
            color: body.color
        };
    };

    const body1 = getBodySymbol(aspect.point1Label);
    const body2 = getBodySymbol(aspect.point2Label);


    if (ASPECT_TYPES[aspect.aspectKey] && ASPECT_TYPES[aspect.aspectKey].orb < aspect.orb) return null;

    const queryForAspect = async (point1Label, point2Label, aspectKey, type) => {
        if (aspectInterpretation) {
            setAspectInterpretation(false)
        } else {
            const aspectRes = await getAspect([point1Label, point2Label], aspectKey, type)

            setAspectInterpretation(aspectRes)
        }

    }

    return (
        <div style={{
                padding: 4,
                paddingTop: 2, paddingBottom: 2,
                //backgroundColor: 'rgba(0,0,0,)',
                borderRadius: 4,
                color: getAspectColor(aspect.aspectKey),
                cursor: "pointer",
                width: isMobile ? 'calc(100% - 16px)' : 'auto',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginBottom: '4px',
                border: isSelected ? '1px solid white' : 
                       isSearchResult ? '1px solid rgba(255,144,20,.66)' : 
                       '1px solid transparent',
            }}
            onClick={() => {
                
                

                
            }}
        >
            <div style={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: 8, marginRight: 8,
            }}>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flex: 1,
                    fontFamily: 'sans-serif',
                    // border: isSearchResult? '1px solid white' : '1px solid transparent'
                    
                }}>
                    <span className="symbolText" style={{ fontSize: 14, color: body1.color,  }}>{body1.symbol}</span>
                    <span style={{ fontSize: 10, textTransform: 'uppercase', color: 'rgba(255,255,255,.54)' }}>{aspect.point1Label}</span>
                    <span style={{ fontSize: 8, fontWeight: 'bold', marginLeft: 2, textTransform: 'uppercase', color: 'rgba(255,255,255,.8)' }}>{aspect.point1Source}</span>
                    <span className="symbolText" style={{ fontSize: 14, margin: '0 4px',  }}>{ASPECT_TYPES[aspect.aspectKey]?.symbol}</span>
                    
                    <span className="symbolText" style={{ fontSize: 14, color: body2.color,  }}>{body2.symbol}</span>
                    <span style={{ fontSize: 10, textTransform: 'uppercase', color: 'rgba(255,255,255,.54)' }}>{aspect.point2Label}</span>
                    <span style={{ fontSize: 8, fontWeight: 'bold', marginLeft: 2, textTransform: 'uppercase', color: 'rgba(255,255,255,.8)' }}>{aspect.point2Source}</span>
                </div>
                <div style={{
                    marginLeft: 8,
                    fontSize: '0.8em',
                    opacity: 1,
                    fontWeight: 'bold',
                }}>
                    {aspect.orb.toFixed(1)}°
                </div>
                <div style={{color: 'rgba(255,255,255,.5)', width: 28, height: 28, display: 'flex', justifyContent: 'center', borderRadius: 4, border: '1px solid rgba(255,255,255,.3)',
                    alignItems: 'center', marginLeft: 8, marginRight: 8}} className="symbolText" onClick={() => {
                        if (isSelected)
                            setSelectedAspect(null)
                        else setSelectedAspect(aspect)
                    }}><div>★</div></div>
                    <div style={{color: 'rgba(255,255,255,.5)',width: 28, height: 28, display: 'flex', justifyContent: 'center', borderRadius: 4, border: '1px solid rgba(255,255,255,.3)',
                    alignItems: 'center',lineHeight: '16px',}} className="symbolText" onClick={() => queryForAspect(aspect.point1Label, aspect.point2Label, aspect.aspectKey, aspect.point1Source === aspect.point2Source ? 'natal' : 'synastry')}>
                        <div>⨁</div>
                    </div>
            </div>
            {aspectInterpretation && aspectInterpretation.map((interp, interpi) => {
                if (!interp.interpretation) return null;
                return <div key={`${interpi}-${interp.source}`} style={{fontSize: 10, maxWidth: '100%', color: 'white', marginBottom: 8, marginLeft: 8, marginRight: 8}}>{truncateAtNWords(interp.interpretation, 128)}
                <br /><div style={{width: '100%', textAlign: 'right', color: 'rgba(255,255,255,.5)'}}>
                excerpt from <span style={{textDecoration: 'underline', cursor: 'pointer', color: 'magenta'}} onClick={() => {
                    window.open(interp.sourceUrl, '_blank')
                }}>{interp.source}</span> on {new Date(interp.timestamp._seconds*1000 || interp.timestamp).toDateString()}</div>
                </div>} )}
        </div>
    );
};