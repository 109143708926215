

export const workerContent = `
          self.BACKEND_URL = '${process.env.REACT_APP_BACKEND_URL || 'http://localhost:3033'}';
          self.postMessage({ type: 'init', message: 'Worker initialized' });
      
          async function calculatePaths(bodies, timestamp, bodyTimestamp, chartIndex) {
            try {
              const response = await fetch(\`\${self.BACKEND_URL}/cartography/paths?timestamp=\${timestamp}&bodyTimestamp=\${bodyTimestamp ? bodyTimestamp : ''}&chartIndex=\${chartIndex}\`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({ bodies })
              });
              
              if (!response.ok) {
                throw new Error(\`HTTP error! status: \${response.status}\`);
              }
              return await response.json();
            } catch (error) {
              console.error('calculatePaths error:', error);
              throw error;
            }
          }
          self.onmessage = async (e) => {
            if (e.data.type === 'test') {
              self.postMessage({ type: 'test-response', message: 'Test received' });
              return;
            }
            const { bodies, timestamp, bodyTimestamp, chartIndex, type, chunkInfo } = e.data;
            try {
              const result = await calculatePaths(bodies, timestamp, bodyTimestamp, chartIndex);
              self.postMessage({
                type: type || 'regular',
                data: result,
                chartIndex,
                chunkInfo
              });
            } catch (error) {
              self.postMessage({
                type: 'error',
                error: error.message,
                chartIndex,
                chunkInfo
              });
            }
          };
        `;