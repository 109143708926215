import React, { useRef, useEffect, useState } from "react"

import { AspectItem } from "./AspectItem";
import { getUniqueRelocSynastryAspects, isAspectInSearch } from "./aspects.helpers";

const colWidth = 200;






const AspectList = React.memo(({ aspects, title, aspectSearch, setSelectedAspect, selectedAspect, isMobile }) => {
    

    const scrollContainerRef = useRef(null);

    useEffect(() => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTop = 0; // Reset scroll to top when selectedAspect changes
        }
    }, [selectedAspect]);

    if (!aspects || aspects.length === 0) return null;

    const selectedAspectInThisList = aspects.find(aspect => 
        aspect.point1Label === selectedAspect?.point1Label && 
        aspect.point2Label === selectedAspect?.point2Label &&
        aspect.point1Source === selectedAspect?.point1Source &&
        aspect.point2Source === selectedAspect?.point2Source
    );

    const searchResults = aspects.filter(aspect => 
        isAspectInSearch(aspect, aspectSearch) && 
        aspect !== selectedAspectInThisList
    );

    const remainingAspects = aspects.filter(aspect => 
        !isAspectInSearch(aspect, aspectSearch) && 
        aspect !== selectedAspectInThisList
    );

    return (
        <div style={{height: 'inherit', maxHeight: 'inherit', width: isMobile ? 'inherit' : 'auto'}}>
            <div style={{
                color: 'white',
                marginBottom: 8,
                fontWeight: 'bold',
                fontSize: '0.9em',
                opacity: 0.8,
                marginTop: 16,
            }}>
                {title}
            </div>
            <div 
                style={{
                    maxHeight: 'calc(100% - 0px)',
                    overflowY: 'auto'
                }}
                ref={scrollContainerRef} // Attach ref to scrollable container
            >
                {selectedAspectInThisList && (
                    <div style={{ marginBottom: 12 }}>
                        <AspectItem
                            aspect={selectedAspectInThisList}
                            setSelectedAspect={setSelectedAspect}
                            isSelected={true}
                            isMobile={isMobile}
                        />
                    </div>
                )}
                
                {searchResults.length > 0 && (
                    <div style={{ marginBottom: 12 }}>
                        <div style={{ fontSize: 12, color: 'rgba(255,255,255,0.6)', marginBottom: 4 }}>
                            Related Aspects
                        </div>
                        {searchResults.map((aspect, index) => (
                            <AspectItem 
                                key={`search-${aspect.point1Label}-${aspect.point2Label}-${index}`}
                                aspect={aspect}
                                setSelectedAspect={setSelectedAspect}
                                isSearchResult={true}
                                isMobile={isMobile}
                            />
                        ))}
                    </div>
                )}

                {remainingAspects.length > 0 && (
                    <div>
                        <div style={{ fontSize: 12, color: 'rgba(255,255,255,0.6)', marginBottom: 4 }}>
                            Other Aspects
                        </div>
                        {remainingAspects.map((aspect, index) => (
                            <AspectItem 
                                key={`other-${aspect.point1Label}-${aspect.point2Label}-${index}`}
                                aspect={aspect}
                                setSelectedAspect={setSelectedAspect}
                                isMobile={isMobile}
                            />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
})





const AspectLists = React.memo(({aspects, aspectSearch, processedAspects, getAspectsNo, selectedAspect, setSelectedAspect, synastryAspects, relocSynastryAspects, showList, isMobile}) => {
    const colMar = 16;
    //const  = props;


    const [uniqueReloc, setUniqueReloc] = useState([]);

    useEffect(() => {
        setUniqueReloc([...getUniqueRelocSynastryAspects(relocSynastryAspects, synastryAspects)]);
    }, [synastryAspects, relocSynastryAspects])

    if (showList === 'A1') {
        return                     <AspectList 
        aspects={processedAspects[1]} 
        aspectSearch={aspectSearch}
        setSelectedAspect={setSelectedAspect}
        selectedAspect={selectedAspect}
        isMobile={isMobile}
        title="Chart 1 Aspects"
        />
    } else if (showList === 'ASYN') {
        return                     <AspectList 
        aspects={synastryAspects} 
        aspectSearch={aspectSearch}
        setSelectedAspect={setSelectedAspect}
        selectedAspect={selectedAspect}
        isMobile={isMobile}
        title="Synastry Aspects"
        />
    }else if (showList === 'A2') {
        return                                        <AspectList 
        aspects={processedAspects[2]} 
        aspectSearch={aspectSearch}
        setSelectedAspect={setSelectedAspect}
        selectedAspect={selectedAspect}
        isMobile={isMobile}
        title="Chart 2 Aspects"
    />
    }else if (showList === 'AREL') {
        return                <AspectList 
        aspects={uniqueReloc} 
        aspectSearch={aspectSearch}
        setSelectedAspect={setSelectedAspect}
        selectedAspect={selectedAspect}
        isMobile={isMobile}
        title="RELOCATION-UNIQUE"
    />
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: 'calc(80dvh - 96px)' }}>
            {/* {selectedAspect && (
                <button 
                    onClick={() => setSelectedAspect(null)}
                    style={{
                        marginBottom: 16,
                        padding: '4px 12px',
                        backgroundColor: 'rgba(255,68,68,0.2)',
                        border: '1px solid #ff4444',
                        borderRadius: 4,
                        color: 'white',
                        cursor: 'pointer'
                    }}
                >
                    Clear Selection
                </button>
            )} */}
            <div style={{
                //width: 45+180*4, 
                backgroundColor: 'rgba(0,0,0,.66)', 
                //border: '1px solid white', 
                height: 'inherit',
                display: 'flex', 
                flexDirection: 'row',
                //padding: 16,
                borderRadius: 8
            }}>
                <div style={{width: colWidth, height: 'inherit',marginRight: colMar}}>
                    <AspectList 
                        aspects={processedAspects[1]} 
                        aspectSearch={aspectSearch}
                        setSelectedAspect={setSelectedAspect}
                        selectedAspect={selectedAspect}
                        title="Chart 1 Aspects"
                    />
                </div>
                
                
                <div style={{width: colWidth, height: 'inherit',marginRight: colMar}}>
                    <AspectList 
                        aspects={processedAspects[2]} 
                        aspectSearch={aspectSearch}
                        setSelectedAspect={setSelectedAspect}
                        selectedAspect={selectedAspect}
                        title="Chart 2 Aspects"
                    />
                </div>
                <div style={{width: colWidth, height: 'inherit',marginRight: colMar}}>
                    <AspectList 
                        aspects={synastryAspects} 
                        aspectSearch={aspectSearch}
                        setSelectedAspect={setSelectedAspect}
                        selectedAspect={selectedAspect}
                        title="Synastry Aspects"
                    />
                </div>
                
                <div style={{width: colWidth, height: 'inherit',marginRight: 0}}>
                    <AspectList 
                        aspects={uniqueReloc} 
                        aspectSearch={aspectSearch}
                        setSelectedAspect={setSelectedAspect}
                        selectedAspect={selectedAspect}
                        title="RELOCATION-UNIQUE"
                    />
                </div>
            </div>
        </div>
    );
});
export default AspectLists;