import { isIOS, isMobileOnly, useDeviceSelectors } from 'react-device-detect';
import useIsIphone from "../../hooks/useIsIphone";
import useIsStandalone from "../../hooks/useIsStandalone";

import AspectLists from "../aspects/AspectLists";
import BodyPositionCard from "../planetarytables/BodyPositionCard";
import HousePositionCard from "../planetarytables/HousePositionCard";
import BodiesInHousesCard from "../planetarytables/BodiesInHousesCard";

import TransitCalendar from '../transits/TransitCalendar';
import { useViewData } from '../../contexts/viewContext';
import TransitPane from '../transits/TransitPane';
import { LinesList } from '../powerlines/LinesList';
import { useRelocation } from '../../contexts/relocationPathsContext';
import TransitModule from '../transits/TransitModule';


const CHART_TABS = [
  'C1', "C2", "R1", "R2", "S1,2", "SR1,2"
]

const ASPECT_TABS = ['A1', 'A2', 'ASYN', 'AREL']

// src/components/MobileUIModal.jsx
const MobileUIModal = ({ 
 isOpen, 
 setIsOpen, 
 wheels, 
 activeUITab, 
 setActiveUITab,
 aspectPaneProps,
}) => {

  const {
    getIsMobile,
    getActiveTabAspect,
    setActiveTabAspect,
    getActiveTabTransit,
    setActiveTabTransit,
    getChartTab,
    setChartTab,
  } = useViewData();

  const {
    relocPaths,
} = useRelocation();


 const buttonStyle = {
   padding: '8px 12px',
   backgroundColor: 'rgba(0,0,0,0.8)',
   border: '1px solid rgba(255,255,255,0.8)',
   borderRadius: '4px',
   color: 'white',
   cursor: 'pointer',
   fontSize: 10,
   margin: '4px',
   transition: '.3s all',
 };

 const activeStyle = {
   ...buttonStyle,
   backgroundColor: 'rgba(255,255,255,1)', 
   color: 'black'
 };

 const isIphone = useIsIphone() && isIOS;
 const isStandalone = useIsStandalone();
 const bigBottomMenu = false;
 const [selectors, data] = useDeviceSelectors(window.navigator.userAgent)
 const cornerPadding = (data.device.vendor === 'Apple' && data.device.model === 'iPhone' && data.device.type === 'mobile' && data.os.name === 'iOS' && isStandalone) ||
 (data.device.vendor === 'Apple' && data.device.model === 'iPhone' && isStandalone)
 
  const isSynastry = wheels[getChartTab()].props.children && wheels[getChartTab()].props.children.props && wheels[getChartTab()].props.children.props.synastry

 return (
   <>
     <div style={{
      position: 'absolute',
      bottom: isOpen ? 0 : 'calc(48px - 75dvh)',
      left: 0,
      maxWidth: '100vw',
      width: 'calc(100vw - 2px)',
      height: '75dvh',
      transition: '0.3s all',
      zIndex: 1000,
      display: 'flex',
    }} onClick={e => e.stopPropagation()}>
      <div style={{
            maxWidth: '100vw',
            width: 'calc(100vw - 2px)',
            border: '1px solid white',
            height: 'calc(75dvh - 48px)',
            marginTop: 48,
            pointerEvents: 'none',
            backgroundColor:'rgba(0,0,0,0.8)',
            borderRadius: 8,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'visible',
            position: 'relative',
            }}>

        <div className='mobileUIModal' style={{
          pointerEvents: 'auto',
          position: 'absolute',
          left: 0,
          zIndex: 1001,
          width: `calc(100vw - ${bigBottomMenu ? 48 : 16}px)`,
          paddingLeft: bigBottomMenu ? 24 : 8,
          paddingRight: bigBottomMenu ? 24 : 8,
          display: 'flex',
          flexDirection: 'row',
          backdropFilter: 'blur(2px)',
          borderTop: '1px solid rgba(255,255,255,.1)',
          marginTop: cornerPadding ? -40 : 0,
        }}>
          <button 
            style={(activeUITab === 'charts' && isOpen) ? activeStyle : buttonStyle}
            onClick={() => {
              if (!isOpen) setIsOpen(true)
              setActiveUITab('charts')
            }}
          >
            CHARTS
          </button>
          <button
            style={(activeUITab === 'reloc' && isOpen) ? activeStyle : buttonStyle} 
            onClick={() => {
              if (!isOpen) setIsOpen(true)
              setActiveUITab('reloc')
            }}
          >
            LINES
          </button>
          <button
            style={(activeUITab === 'aspects' && isOpen) ? activeStyle : buttonStyle}
            onClick={() => {
              if (!isOpen) setIsOpen(true)
              setActiveUITab('aspects')
            }}
          >
            ASPECTS
          </button>
          <button
            style={(activeUITab === 'transits' && isOpen) ? activeStyle : buttonStyle} 
            onClick={() => {
              if (!isOpen) setIsOpen(true)
              setActiveUITab('transits')
            }}
          >
            TRANSITS
          </button>

          <div style={{flex: 1}}/>

          <button
            onClick={() => setIsOpen(!isOpen)}
            style={{
              ...buttonStyle,
              marginRight: 4
            }}
          >
            {isOpen ? '▼' : '▲'}
          </button>
        </div>

        {isOpen && <>
          {activeUITab === 'charts' && (
            <>
              <div style={{
                display: 'flex',
                borderTop: '1px solid rgba(255,255,255,0.1)',
                backgroundColor: 'rgba(0,0,0,0.8)',
                width: 'calc(100% - 2px)',
                borderRadius: 8,
                overflow: 'hidden',
                pointerEvents: 'auto',
              }}>
                {wheels.map((_, index) => (
                  <button
                    key={index}
                    onClick={() => setChartTab(index)}
                    style={{
                      flex: 1,
                      padding: '12px',
                      backgroundColor: getChartTab() === index ? 'rgba(255,255,255,0.1)' : 'transparent',
                      border: 'none',
                      color: 'white',
                      cursor: 'pointer'
                    }}
                  >
                    {CHART_TABS[(index)]}
                  </button>
                ))}
              </div>
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                maxWidth: '100vw',
                width: '100vw',
                pointerEvents: 'auto',
                overflow: 'auto',
                marginBottom: 24,
              }}>
                <div style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                   marginTop: 64, marginBottom: 64,
                }}>
                  {wheels[getChartTab()]}
                </div>
                {isSynastry ? null : <div style={{padding: '0 16px', color: 'white'}}>
                  <BodyPositionCard positionData={{
                    positions: wheels[getChartTab()].props.bodies.map(body => ({
                      body: body.name,
                      sign: Math.floor(body.degree / 30),
                      signDegree: body.degree % 30,
                      degree: body.degree,
                      longitudeSpeed: body.longitudeSpeed || 0
                    }))}} 
                    cusps={wheels[getChartTab()].props.cusps}
                  />
                  <HousePositionCard cusps={wheels[getChartTab()].props.cusps} bodies={wheels[getChartTab()].props.bodies}  />
                </div>}
              </div>
            </>
          )}

          {activeUITab === 'aspects' && (
            <>
              <div style={{
                display: 'flex',
                borderTop: '1px solid rgba(255,255,255,0.1)',
                backgroundColor: 'rgba(0,0,0,0.8)',
                width: 'calc(100% - 0px)',
                borderRadius: 8,
                minHeight: 40,
                overflow: 'hidden',
                pointerEvents: 'auto',
              }}>
                {ASPECT_TABS.map((title, index) => (
                  <button
                    key={index}
                    onClick={() => setActiveTabAspect(index)}
                    style={{
                      flex: 1,
                      padding: '12px',
                      backgroundColor: getActiveTabAspect() === index ? 'rgba(255,255,255,0.1)' : 'transparent',
                      border: 'none',
                      color: 'white',
                      cursor: 'pointer'
                    }}
                  >
                    {title}
                  </button>
                ))}
              </div>
              <div style={{maxHeight: 'calc(100% - 40px)', width: 'calc(100% - 16px)', marginLeft: 8, overflowY: 'scroll', pointerEvents: 'auto'}}>
                <AspectLists 
                  {...aspectPaneProps}
                  showList={ASPECT_TABS[getActiveTabAspect()]}
                  isMobile={getIsMobile()}
                />
              </div>
            </>
          )}

          {activeUITab === 'reloc' && (relocPaths?.length > 0 ? 
            <LinesList />
           : <div style={{padding: 16, color: 'white', display: 'flex', flexDirection: 'column', overflowY: 'scroll', position: '', maxHeight: 'calc(100% - 40px)', pointerEvents: 'auto'}}>turn on relocation and click a spot</div>)
          }

{activeUITab === 'transits' && (
        <>
          {/* <div style={{
            display: 'flex',
            borderTop: '1px solid rgba(255,255,255,0.1)',
            backgroundColor: 'rgba(0,0,0,0.8)',
            width: 'calc(100% - 2px)',
            borderRadius: 8,
            overflow: 'hidden',
            pointerEvents: 'auto',
          }}>
            {['C1', 'C2'].map((tab, index) => (
              <button
                key={index}
                onClick={() => setActiveTabTransit(index)}
                style={{
                  flex: 1,
                  padding: '12px',
                  backgroundColor: getActiveTabTransit() === index ? 'rgba(255,255,255,0.1)' : 'transparent',
                  border: 'none',
                  color: 'white',
                  cursor: 'pointer'
                }}
              >
                {tab}
              </button>
            ))}
          </div> */}
          <div style={{
            flex: 1,
            overflowY: 'hidden',
            pointerEvents: 'auto',
            height: 'calc(100% - 48px)',
            backgroundColor: 'transparent',
          }}>
            {/* <TransitCalendar /> */}
            <TransitModule />
          </div>
        </>
      )}
        </>}
        
      </div>
    </div>
   </>
 );
};

export default MobileUIModal;
