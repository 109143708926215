import { elementColors, getSignPosition } from "../data/zodiacConstants";
import { renderToStaticMarkup } from 'react-dom/server';

const MARKER_THRESHOLD = 16; // Degrees
const LAT_THRESHOLD = 60; // Maximum latitude (positive or negative)

export const chunkArray = (array, size) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += size) {
      chunks.push(array.slice(i, i + size));
    }
    return chunks;
};

const getDistance = (lat1, lng1, lat2, lng2) => {
    return Math.sqrt(Math.pow(lat2 - lat1, 2) + Math.pow(lng2 - lng1, 2));
};

const findClosestValidPoint = (points, existingMarkers, originalLat, originalLng) => {
    if (!Array.isArray(points)) return null;

    // Filter points within latitude threshold
    const validPoints = points.filter(point => 
        Array.isArray(point) && 
        point.length === 2 && 
        typeof point[0] === 'number' && 
        typeof point[1] === 'number' &&
        Math.abs(point[0]) <= LAT_THRESHOLD
    );

    if (validPoints.length === 0) return null;

    // Sort points by distance to original marker
    const sortedPoints = validPoints.map(point => ({
        point,
        distToOriginal: getDistance(point[0], point[1], originalLat, originalLng)
    })).sort((a, b) => a.distToOriginal - b.distToOriginal);

    // Try each point in order of proximity to original marker
    for (const {point, distToOriginal} of sortedPoints) {
        const [lat, lng] = point;

        // Check if point is far enough from all existing markers
        const minDistanceToMarkers = Math.min(
            ...existingMarkers.map(marker => 
                getDistance(lat, lng, marker.lat, marker.lng)
            ),
            Infinity
        );

        // If this point is far enough from other markers and not too far from original
        if (minDistanceToMarkers > MARKER_THRESHOLD && distToOriginal < MARKER_THRESHOLD * 2) {
            return { lat, lng };
        }
    }
    return null;
};

export const processMarkers = (paths,) => {
    const markers = [];
    const newElements = new Set();

    // First process PARANS markers
    paths.forEach(line => {
        if (line.type === 'PARANS' && line.marker) {
            let div = document.createElement('DIV');
            div.innerHTML = renderToStaticMarkup(divGenerator(line));
            const element = div.firstChild;
            newElements.add(element);
            markers.push({...line.marker, element});
        }
    });

    // Then process non-PARANS markers
    paths.forEach(line => {
        if (line.type !== 'PARANS' && line.marker) {
            const markerNearby = markers.some(existingMarker => 
                getDistance(line.marker.lat, line.marker.lng, existingMarker.lat, existingMarker.lng) <= MARKER_THRESHOLD
            );

            let markerPosition = line.marker;
            if (markerNearby && Array.isArray(line.points) && line.points.length > 0) {
                const newPoint = findClosestValidPoint(
                    line.points,
                    markers,
                    line.marker.lat,
                    line.marker.lng
                );
                if (newPoint) {
                    markerPosition = {...line.marker, ...newPoint};
                }
            }

            let div = document.createElement('DIV');
            div.innerHTML = renderToStaticMarkup(divGenerator({...line, marker: markerPosition}));
            const element = div.firstChild;
            newElements.add(element);
            markers.push({...markerPosition, element});
        }
    });

    return {markers, markerElements: newElements};
};

export const targetGenerator = () => {
    return <div style={{
        width: 24,
        fontWeight: 800, 
        height: 24,
        display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', textAlign: 'center', 
        borderRadius: 48,
        fontSize: 20, fontFamily: 'math',
        border: `4px solid white`, color: 'white', }}
    >✈</div>
}

export const MarkerDivFromLine = ({line, globe}) => {
    return divGenerator(line, globe)
}

export const processSymbol = (symbol, circle=false) => {
    if (circle)
        return <span style="position: relative; display: inline-block;">{symbol}
    <span style="position: absolute; top: 0; left: 0; width: 1em; height: 1em; border: 1px solid black; border-radius: 50%;"></span>
  </span>
}

export const CircleAroundSymbol = ({symbol, color, circle=false}) => {
    return circle ? <span className="symbolText" style={{position: 'relative', display: 'inline-block', fontSize:12, width: 10, lineHeight: '10px',}}>{symbol}
    <span style={{position: "absolute", top: 0, left: 0, width: "12px", height: "12px",marginLeft: -2, marginTop: -1,border: `1px solid ${color}`, borderRadius:' 50%',}}></span>
  </span> : <span className="symbolText" style={{lineHeight: '12px'}}>{symbol}</span>
}

export const divGenerator = (line, globe=true) => {
    if (line.marker.type === 'PARANS') return <div style={{
        width: line.marker.size, 
        pointerEvents: 'none',
        fontWeight: 800, 
        height: line.marker.size, 
        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', 
        borderRadius: line.marker.synastryPath ? 8 : 2,
        fontSize: 9, 
        borderTop: `1px solid ${line.marker.body1.color}`, 
        borderLeft: line.marker.synastryPath ? `1px solid ${line.marker.body2.color}` : `1px solid ${line.marker.body1.color}`, 
        borderBottom: line.marker.synastryPath ? `1px solid ${line.marker.body1.color}` : `1px solid ${line.marker.body2.color}`, 
        borderRight: `1px solid ${line.marker.body2.color}`, 
        backgroundColor: 'rgba(0,0,0,.3)',
        color: line.marker.body.color, }}
    >
        <div style={{color: 'white', marginTop: 4, position: 'relative', lineHeight: '10px'}}>
            <span className="symbolText" style={{ color: line.marker.body1.color}} >{line.marker.body1.symbol}</span>
            <span style={{fontFamily: 'sans-serif',color: line.marker.body1.color}}>{line.marker.lineLabel1}{line.marker.synastryPath && line.marker.index}</span><br />
            <span className="symbolText" style={{ color: line.marker.body2.color}} >{line.marker.body2.symbol}</span>
            <span style={{fontFamily: 'sans-serif',color: line.marker.body2.color}}>{line.marker.lineLabel2}{line.marker.synastryPath && line.marker.synastryIndex}</span>
            <span className="symbolText"  style={{position: 'absolute', top: line.marker.size/2+2, left: line.marker.size/2, width: '100%', textAlign: 'center', fontSize: 14, opacity: .5, color: 'white'}}>{!line.marker.synastryPath && line.marker.index}</span>
        </div>
    </div>

    const sign = getSignPosition(line.marker.body ? line.marker.body.degree : line.marker.body1.degree);
    const symbol = sign && sign.sign && sign.sign.symbol;
    return <div className="lineMarker" style={{
        width: line.marker.size, 
        fontWeight: 800, 
        height: line.marker.size, 
        pointerEvents: 'none',
        display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', textAlign: 'center', 
        borderRadius: line.marker.size, 
        backgroundColor: 'rgba(0,0,0,.3)',
        fontSize: 12, lineHeight: '16px',
        border: `1px solid ${line.marker.body.color}`, color: line.marker.body.color, }}
    >
        <div style={{position: 'relative'}}>
            <div style={{marginTop: (globe && line.marker.size > 30) ? 4 : 0}}>
                <CircleAroundSymbol symbol={line.marker.body.symbol} circle={line.marker.body.circle} color={line.marker.body.color} /> {symbol ? <span className="symbolText" style={{ color: elementColors[sign.sign.element], fontSize: 10, lineHeight: '10px'}} >{symbol}</span> : null}
            </div>
            <div className="symbolText spFrom" style={{fontFamily: 'sans-serif', marginTop: -2, fontSize: 10, lineHeight: '12px'}}>{line.marker.lineLabel}</div> 
            {line.marker.synastryPath ? <><div className="symbolText"  style={{
                fontFamily: 'sans-serif', position: 'absolute', top: 0, left: 0, width: '100%', textAlign: 'center', fontSize: 10, lineHeight: '20px', marginLeft: -line.marker.size/2+2, marginTop: -line.marker.size/2+2, opacity: .5, color: line.marker.body.color.replace('.5)', '1)')
            }}>{`${line.marker.index}`}</div>
            <div 
                className="diagonal-line" 
                style={{
                height: line.marker.size,
                transform: 'rotate(-45deg)',
                top: -line.marker.size*.1,
                left: line.marker.size*.4,
                backgroundColor: line.marker.body.color.replace('.5)', '1)')
                }}
            />
            <div className="symbolText spTo"  style={{
                fontFamily: 'sans-serif', position: 'absolute', top: 0, left: 0, width: '100%', textAlign: 'center', fontSize: 10, lineHeight: '20px', marginLeft: line.marker.size/2-2, marginTop: line.marker.size/2+5, opacity: .5, color: line.marker.body.color.replace('.5)', '1)')
            }}>{`${3-line.marker.index}`}</div>
            </>
             : <div className="symbolText"  style={{
                fontFamily: 'sans-serif', position: 'absolute', top: 0, left: 0, width: '100%', textAlign: 'center', fontSize: 16, lineHeight: '20px', marginLeft: line.marker.size/2-2, marginTop: line.marker.size/2+5, opacity: .5, color: line.marker.body.color
            }}>{line.marker.index}</div>}
            
        </div>
    </div>
}
