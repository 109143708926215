import AspectLists from "./AspectLists";
import { AspectItem } from "./AspectItem";

// const TITLES = [
//     'C1', "C2", "R1", "R2", "S1,2", "SR1,2"
// ]
const AspectModal = ({ 
  isOpen, 
  setIsOpen, 
  aspectPaneProps,
  activeTab, 
  setActiveTab, isMobile,
}) => {
    const  {selectedAspect,setSelectedAspect} = aspectPaneProps
  return (
    <div style={{
      position: 'absolute',
      bottom: isOpen ?  isMobile ? 0: "16px" :'calc(48px - 80dvh)' ,
      left: 0,
      maxWidth: '100vw',
      width: '960px',
      height: '80dvh',
      marginLeft: isMobile ? 0 : 'calc(50vw - 400px)',
      marginRight: isMobile ? 0 : 'calc(50vw - 400px)', 
      transition: '0.3s all',
      zIndex: 1000,
      display: 'flex',
      pointerEvents: 'none',
      
    }}>
        <div style={{
            maxWidth: '960px',
            width: '960px',
            border: '1px solid rgba(255,255,255,0.3)',
            height: 'calc(80dvh - 48px)',
            marginTop: 48,
            borderRadius: 8,
            backgroundColor: isMobile ? 'rgba(0,0,0,0.8)': 'rgba(0,0,0,0.54)',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            pointerEvents: 'auto', marginBottom: 8, 
            }}>
      {/* Toggle Button - always visible */}
      <div style={{
        position: 'absolute',
        top: 0,
        right: isMobile ? 8 : 'calc(40vw - 300px)',
        zIndex: 1001, color: 'white', display: 'flex', flexDirection: 'row', alignItems: 'center',justifyContent: 'center'
      }}>
        {selectedAspect && <span style={{ fontSize: 10,  position: 'absolute', bottom: 2, zIndex: 3, left: 16,  }}>SELECTED ASPECT</span>}
        {selectedAspect && 
            <div style={{backgroundColor: 'rgba(0,0,0,.8)', border: '1px solid rgba(255,255,255,.3)',borderRadius: 8,}}><AspectItem aspect={selectedAspect} setSelectedAspect={() => {}} /></div>
        }
        {selectedAspect &&  <button 
                    onClick={() => setSelectedAspect(null)}
                    style={{
                        
                        marginLeft: -8,
                        padding: '2px 6px',
                        marginRight: 8,
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        border: '1px solid #ff4444',
                        borderRadius: 4,
                        color: 'white',
                        cursor: 'pointer'
                    }}
                >
                    ✕
                </button>}
        <button
          onClick={() => setIsOpen(!isOpen)}
          style={{
            padding: '8px 8px',
            backgroundColor: 'rgba(0,0,0,0.8)',
            border: '1px solid rgba(255,255,255,0.8)',
            borderRadius: '4px',
            color: 'white',
            cursor: 'pointer',fontSize: 10,marginTop: 8,
          }}
        >
          ASPECTS {isOpen ? '▼' : '▲'}
        </button>
      </div>

      {/* Content Area */}
      {/* {isMobile && selectedAspect && (<div style={{
        position: 'absolute',
        top: 56,
        right: isMobile ? 8 : 400,
        zIndex: 1001, color: 'white'
      }}>
        
        <button 
                    onClick={() => setSelectedAspect(null)}
                    style={{
                        marginBottom: 16,
                        padding: '4px 12px',
                        backgroundColor: 'rgba(255,68,68,0.2)',
                        border: '1px solid #ff4444',
                        borderRadius: 4,
                        color: 'white',
                        cursor: 'pointer'
                    }}
                >
                    Clear Selection
                </button>
      </div>
            )} */}
      {isOpen ? <div style={{
        flex: 1,
        //overflow: 'auto',
        //padding: '8px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: '100vw',
        width: '960px',
        overflow: 'hidden',
        height: 'calc(80vh - 80px)',
        
      }}>
        
        <AspectLists 
                 {...aspectPaneProps}
                 showList={isMobile && activeTab}
                 isMobile={isMobile}
        />  
      </div> : <div style={{height: 24}} />}

      {/* Tab Bar */}
      {isMobile && <div style={{
        display: 'flex',
        //height: 'inherit',
        borderTop: '1px solid rgba(255,255,255,0.1)',
        backgroundColor: 'rgba(0,0,0,0.8)'
      }}>
                {['A1', 'A2', 'ASYN', 'AREL'].map((title, index) => (
          <button
            key={index}
            onClick={() => setActiveTab(title)}
            style={{
              flex: 1,
              padding: '12px',
              backgroundColor: activeTab === index ? 'rgba(255,255,255,0.1)' : 'transparent',
              border: 'none',
              color: 'white',
              cursor: 'pointer'
            }}
          >
            {title}
          </button>
        ))}
      </div>}
    </div>
    </div>
  );
};

export default AspectModal;